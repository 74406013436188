<template>
  <div>
    <div class="address-book" v-if="loading == false">
      <div class="row">
        <div class="col-lg-3 col-md-4 address-book-wrap">
          <h3 class="delivery mb-0 w-100">Deliver to</h3>
          <div class="address d-flex justify-content-center align-items-center flex-wrap">
            <div class="add-button">
              <button
                type="button"
                class="d-block mx-auto add-btn"
                @click="openAddAddressPopup()"
              >
                <span class="d-block">
                  <svg class="d-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="21px" height="21px" viewBox="0 0 21 21" enable-background="new 0 0 21 21" xml:space="preserve">
                    <line fill="#FFFFFF" x1="10.5" y1="0.5" x2="10.5" y2="20.5"/>
                    <line fill="none" stroke="#F59221" stroke-linecap="round" stroke-miterlimit="10" x1="10.5" y1="0.5" x2="10.5" y2="20.5"/>
                    <line fill="#FFFFFF" x1="20.5" y1="10.5" x2="0.5" y2="10.5"/>
                    <line fill="none" stroke="#F59221" stroke-linecap="round" stroke-miterlimit="10" x1="20.5" y1="10.5" x2="0.5" y2="10.5"/>
                  </svg>
                </span>
              </button>
              <h6 class="mt-3">Add Address</h6>
            </div>
          </div>
        </div>
        <div
          :class="(address.is_default=='1') ? 'col-lg-3 col-md-4 address-book-wrap active':'col-lg-3 col-md-4 address-book-wrap'"
          v-for="(address,index) in getaddressdetails.data"
          v-bind:key="index"
          v-show="getaddressdetails!='' && getaddressdetails.data!=''"
        >
          <h3 class="delivery mb-0 w-100">Deliver to</h3>
          <div class="address d-flex flex-column">
            <div
              class="address-one w-100"
              @click="setDeliveryAddress(address,address.customer_address_id)"
              style="cursor: pointer;"
            >
              <h5>{{address.alias}}<span class="incomplete-address">{{address.address2 != "" ? '' : ' (Incomplete Address)'}}</span></h5>
              <p>
                <span v-if="address.address2">{{address.address2}},</span>
                <br>
                <span v-if="address.address2">{{address.address}}</span>
                <span v-if="address.area_name">{{address.area_name}},</span>
                <br>
                <span v-if="address.city_name">{{address.city_name}},</span>
                <br>
                <span>{{address.state_name}} {{address.zipcode}}</span>
              </p>
            </div>
            <div class="d-flex justify-content-md-between mt-auto">
              <button
                :style="colorObj"
                class="edit-btn mr-3 mr-md-0 hoverEffect"
                @click="editAddress(address.customer_address_id);"
              >Edit</button>
              <button :style="{color:addtextColor, borderColor:addtextColor}" class="delete-btn" @mouseleave="changeColorOnLeave" @mouseover="changeColorOnHover" @click="removeAddress(address,address.customer_address_id, address.is_default)">Delete</button>
            </div>
          </div>
          
          <edit-address-book ref="editaddressbook" :addresslist="address" @update="updateAddress"></edit-address-book>
        </div>
      </div>
      <div
        class="modal fade address-popup edit-addressbook-popup"
        id="address-pop-up"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="static" data-keyboard="false"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
              <button type="button" class="close" @click="closeAddAddress()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="modal-body">
                <h2 class="page-heading text-center">Add Address</h2>
                <div class="bottom-section1">

                  <div
                    class="alert alert-danger"
                    role="alert"
                    v-show="enableerradrsmessage"
                  >{{ geterraddrssmsg}}</div>
                  <form
                    class="address-form form-horizontal animated-form"
                    @submit.prevent="addAddress"
                    method="post"
                  >
                    <div class="form-row">
                      <div class="form-group form-group-placeorder checkbox col-md-12">
                        <input
                          type="checkbox"
                          id="default_address"
                          v-model="default_address"
                          name="default_address"
                        >
                        <label for="default_address">Default Address</label>
                      </div>
                    </div>
                    <div class="form-row-gmap" v-if="use_google_map == 1">
                    <GmapMap
                    :center="{lat:this.latitude, lng:this.longitude}"
                    @click="center=m.position"
                    :zoom="zoomProperty"
                    map-type-id="roadmap"
                    ref="addAddressMap"
                    style="width:100%; height: 300px;margin-bottom: 30px;margin-left: auto;margin-right: auto;"
                    >
                    <GmapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="true"
                    @click="center=m.position"
                    @dragend="updateCoordinates"
                    />
                    </GmapMap>
                    </div>      
                    <div class="form-row form-row-autocomplete" v-if="use_google_map == 1">
                    <div class="form-group col-md-12 active">
                    <form method="post" class="autoCompleteAddress">
                    <div>
                    <vue-google-autocomplete
                    ref="mapAddAddress"
                    id="mapAddAddress"
                    classname="form-control"
                    placeholder="Please type your address"
                    v-on:placechanged="getAddressData"
                    @keypress.enter="$event.preventDefault()"
                    v-model="formatted_address"
                    types=""
                    :country="country_name"
                    >
                    </vue-google-autocomplete>
                    <div @click="getLocation()" class="location_icon">
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 183.8 183.8" style="enable-background:new 0 0 183.8 183.8;" xml:space="preserve">
<g>
	<path d="M182.2,96.6c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.3,0.3-0.5c0,0,0-0.1,0.1-0.1
		c0.1-0.2,0.2-0.4,0.3-0.6c0,0,0-0.1,0.1-0.1c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3
		c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
		c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
		c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0-0.8-0.1-1.5-0.4-2.2c0,0,0-0.1,0-0.1c-0.1-0.2-0.1-0.4-0.2-0.6
		c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.6-0.7-1.1-1.2-1.5c-1.5-1.5-3.5-2.5-6-2.6c-0.1,0-0.2,0-0.3,0
		c0,0-0.1,0-0.1,0c-0.2,0-0.3,0-0.5,0c-2.8-0.1-5.7-0.2-8.5-0.1c-0.4,0-0.8,0-1.2,0c-1,0-2.1,0-3.1,0c-1.3-0.2-2.1-0.9-2.6-2.1
		c0-0.1,0-0.2-0.1-0.2c-1.4-8.3-4-15.9-7.8-22.9c-2-3.6-4.2-7-6.8-10.1c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3-0.3-0.6-0.7-0.8-1
		c-3.3-3.8-7-7.2-11.2-10.2c-0.3-0.2-0.5-0.4-0.8-0.6c-7.5-5.4-16-9.1-25.2-11.2c-1.6-0.4-3.2-0.8-4.8-1.1c-0.4-0.3-0.7-0.6-1-1
		c-0.4-0.5-0.6-1.1-0.8-1.7c0-0.9,0-1.8,0-2.6c0.1-3.3,0.1-6.5,0-9.8c0-0.1,0-0.3,0-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3
		C99.6,5.6,98.6,3.5,97,2C97,2,97,2,97,1.9c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.2
		c-0.1,0-0.1-0.1-0.2-0.1C96,1.2,95.8,1.1,95.7,1c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c0,0-0.1,0-0.1-0.1
		c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
		c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0
		c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0C92,0,92,0,92,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0
		c0,0,0,0-0.1,0c-0.8,0-1.5,0.1-2.2,0.4c0,0-0.1,0-0.1,0c-0.2,0.1-0.4,0.1-0.6,0.2c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0-0.2,0.1-0.3,0.1
		c-0.6,0.3-1.1,0.7-1.5,1.2c-1.5,1.5-2.5,3.5-2.6,6c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,0.2,0,0.3,0,0.5c-0.1,2.8-0.2,5.7-0.1,8.5
		c0,0.4,0,0.8,0,1.2c0,1,0,2.1,0,3.1c-0.2,1.3-0.9,2.1-2.1,2.6c-0.1,0-0.2,0-0.2,0.1c-8.3,1.4-15.9,4-22.9,7.8
		c-3.6,2-7,4.2-10.1,6.8c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3,0.3-0.7,0.6-1,0.8c-3.8,3.3-7.2,7-10.2,11.2c-0.2,0.3-0.4,0.5-0.6,0.8
		c-5.4,7.5-9.1,16-11.2,25.2c-0.4,1.6-0.8,3.2-1.1,4.8c-0.3,0.4-0.6,0.7-1,1c-0.5,0.4-1.1,0.6-1.7,0.8c-0.9,0-1.8,0-2.6,0
		c-3.3-0.1-6.5-0.1-9.8,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-2.5,0.1-4.6,1.2-6.1,2.7c0,0-0.1,0.1-0.1,0.1
		C1.9,86.9,1.8,87,1.8,87c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.2C1.2,87.8,1.1,88,1,88.2
		c0,0,0,0.1-0.1,0.1c-0.1,0.2-0.2,0.4-0.3,0.6c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2
		c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1
		c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1
		c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.8,0.1,1.5,0.4,2.2c0,0,0,0.1,0,0.1c0.1,0.2,0.1,0.4,0.2,0.6
		c0.1,0.1,0.1,0.3,0.2,0.4c0,0.1,0.1,0.2,0.1,0.3c0.3,0.6,0.7,1.1,1.2,1.5c1.5,1.5,3.5,2.5,6,2.6c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0
		c0.2,0,0.3,0,0.5,0c2.8,0.1,5.7,0.2,8.5,0.1c0.4,0,0.8,0,1.2,0c1,0,2.1,0,3.1,0c1.3,0.2,2.1,0.9,2.6,2.1c0,0.1,0,0.2,0.1,0.2
		c1.4,8.3,4,15.9,7.8,22.9c2,3.6,4.2,7,6.8,10.1c0.1,0.1,0.2,0.2,0.2,0.3c0.3,0.3,0.6,0.7,0.8,1c3.3,3.8,7,7.2,11.2,10.2
		c0.3,0.2,0.5,0.4,0.8,0.6c7.5,5.4,16,9.1,25.2,11.2c1.6,0.4,3.2,0.8,4.8,1.1c0.4,0.3,0.7,0.6,1,1c0.4,0.5,0.6,1.1,0.8,1.7
		c0,0.9,0,1.8,0,2.6c-0.1,3.3-0.1,6.5,0,9.8c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0.1,2.5,1.2,4.6,2.7,6.1
		c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0.1,0.2,0.1
		c0.2,0.1,0.3,0.2,0.5,0.3c0,0,0.1,0,0.1,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0,0,0.1,0,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0
		c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0.1
		c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.8,0,1.5-0.1,2.2-0.4
		c0,0,0.1,0,0.1,0c0.2-0.1,0.4-0.1,0.6-0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0,0.2-0.1,0.3-0.1c0.6-0.3,1.1-0.7,1.5-1.2
		c1.5-1.5,2.5-3.5,2.6-6c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0-0.5c0.1-2.8,0.2-5.7,0.1-8.5c0-0.4,0-0.8,0-1.2
		c0-1,0-2.1,0-3.1c0.2-1.3,0.9-2.1,2.1-2.6c0.1,0,0.2,0,0.2-0.1c8.3-1.4,15.9-4,22.9-7.8c3.6-2,7-4.2,10.1-6.8
		c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.3,0.7-0.6,1-0.8c3.8-3.3,7.2-7,10.2-11.2c0.2-0.3,0.4-0.5,0.6-0.8c5.4-7.5,9.1-16,11.2-25.2
		c0.4-1.6,0.8-3.2,1.1-4.8c0.3-0.4,0.6-0.7,1-1c0.5-0.4,1.1-0.6,1.7-0.8c0.9,0,1.8,0,2.6,0c3.3,0.1,6.5,0.1,9.8,0c0.1,0,0.3,0,0.4,0
		c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c2.5-0.1,4.6-1.2,6.1-2.7c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2
		C182.1,96.7,182.1,96.7,182.2,96.6z M118.1,137.4c-7.6,4.1-16.5,6.4-26.5,6.6c-0.6,0.1-1.3,0.1-2,0.1c-9.3-0.6-17.6-3-24.7-7.1
		c-7.9-4.7-14.2-11.4-18.5-18.9c-4.1-7.6-6.4-16.5-6.6-26.5c-0.1-0.6-0.1-1.3-0.1-2c0.6-9.3,3-17.6,7.1-24.7
		c4.7-7.9,11.4-14.2,18.9-18.5c7.6-4.1,16.5-6.4,26.5-6.6c0.6-0.1,1.3-0.1,2-0.1c9.3,0.6,17.6,3,24.7,7.1
		c7.9,4.7,14.2,11.4,18.5,18.9c4.1,7.6,6.4,16.5,6.6,26.5c0.1,0.6,0.1,1.3,0.1,2c-0.6,9.3-3,17.6-7.1,24.7
		C132.3,126.7,125.6,133,118.1,137.4z"/>
	<path d="M117.4,86.9c-1-5.4-3.8-10.2-7.7-13.8c-4.6-4.4-10.8-7.2-17.6-7.2c-1.8,0-3.6,0.2-5.3,0.5c-5.4,1-10.2,3.8-13.8,7.7
		c-4.4,4.6-7.2,10.8-7.2,17.6c0,1.8,0.2,3.6,0.5,5.3c1,5.4,3.8,10.2,7.7,13.8c4.6,4.4,10.8,7.2,17.6,7.2c1.8,0,3.6-0.2,5.3-0.5
		c5.4-1,10.2-3.8,13.8-7.7c4.4-4.6,7.2-10.8,7.2-17.6C117.9,90.4,117.7,88.6,117.4,86.9z M99.8,98.4c-0.5,0.6-1.1,1.1-1.7,1.6
		c-1.7,1.2-3.8,1.9-6.3,1.9c-2.6,0-4.8-0.8-6.5-2.1c-0.6-0.5-1.1-1.1-1.6-1.7c-1.2-1.7-1.9-3.8-1.9-6.3c0-2.5,0.8-4.8,2.1-6.5
		c0.5-0.6,1.1-1.1,1.7-1.6c1.7-1.2,3.8-1.9,6.3-1.9c2.6,0,4.8,0.8,6.5,2.1c0.6,0.5,1.1,1.1,1.6,1.7c1.2,1.7,1.9,3.8,1.9,6.3
		C101.9,94.5,101.2,96.7,99.8,98.4z"/>
</g>
</svg>
                    </div>
                    </div>
                    </form>
                    </div>
                      </div>
                    <div class="form-row">
                      <div class="form-group col-md-12 active">
                        <label :style="textcolor" class="control-label" for="address">
                          Apt Number
                        </label>
                        <input
                          type="text"
                          name="address"
                          class="form-control"
                          id="address"
                          v-model="address"
                          :class="{error: errors.has('address')}"
                          maxlength="70"
                        >
                        <span
                          v-show="errors.has('address')"
                          class="help is-danger"
                        >{{ $entrallfldrqrd }}</span>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-group col-md-12 d-flex flex-wrap justify-content-center">
                        <div
                          class="radio-dt"
                          v-for="(location,index) in location_types"
                          v-bind:key="index"
                        >
                          <input
                            type="radio"
                            :id="location.val"
                            v-model="location_type"
                            :value="index"
                            @click="selectLocationType(location.id)"
                          >
                          <label :for="location.val">{{location.val}}</label>
                        </div>
                      </div>
                    </div>

                    <div class="form-row" v-if="other_location">
                      <div class="form-group col-md-12 active">
                        <label :style="textcolor" class="control-label" for="tag">Tag</label>
                        <input type="text" name="tag" class="form-control" id="tag" v-model="tag">
                      </div>
                    </div>

                    <div class="form-group text-center">
                      <button type="submit" :style="colorObj" class="btn add-btn hoverEffect" :disabled="enablesavesucadrsmesg">Add</button>
                    </div>
                  </form>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="loading==true">
      <p class="text-center">
        <img src="../../assets/images/loading.gif" />
      </p>
    </div>
  </div>
</template>
<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import EditAddressbook from "../address-book/EditAddressbook";
import { bus } from '../../main'
import helper from '../../store/helper';
import localconfig from "../../global_settings.json";
import axios from "axios";
export default {
  name: "Addressbook",
  components: {
    "edit-address-book": EditAddressbook,
    'vue-google-autocomplete': VueGoogleAutocomplete,
  },
  mixins:[helper],
  data() {
    return {
      addtextColor: "",
      loading: true,
      country_filter_code:'',
      enablesucadrsmessage: false,
      enableerradrsmessage: false,
      geterraddrssmsg: "",
      getaddressstatus: "",
      enablesavesucadrsmesg: false,
      enableupdtadrsmessage: false,
      updtsuccessadrsmsg: "",
      getsaveaddressmsg: "",
      getaddressdetails: [],
      isAutoCompleteDataSelected:false,
      default_address: "",
      states: null,
      cities: null,
      areas: null,
      edtdefault_address: "",
      userprofile: null,
      location_type: "0",
      address: "",
      address1:"",
      address2: "",
      city_id: "",
      state_id: "",
      area_id: "",
      pincode: "",
      zipcode1:"",
      street:"",
      remarks:"",
      country:"",
      state:"",
      city:"",
      other_location: false,
      tag: "",
      profile: {
        user_id: ""
      },
      statebody: {
        country: ""
      },
      citybody: {
        state: ""
      },
      areabody: {
        city: ""
      },
      customer_address: {
        user_id: "",
        customer_address_id: "",
        alias: "",
        alias_code: "",
        address1: "",
        address2: "",
        zipcode1:"",
        street:"",
        is_default: "",
        latitude:"",
        longitude:"",
        remarks:"",
        country:"",
        state:"",
        city:""
      },
      remove_customer_address: {
        customer_address_id: "",
        user_id: "",
        is_deleted: "",
        alias: "",
        alias_code: "",
        address1: "",
        address2: "",
        zipcode1:"",
        street:"",
        is_default: "",
        latitude:"",
        longitude:"",
        remarks:"",
        country:"",
        state:"",
        city:""
      },
      location_types: [
        { id: "0", val: "Home" },
        { id: "1", val: "Office" },
        { id: "2", val: "Other" }
      ],
      markers:[],
      center: { lat:"", lng:"" },
      latitude:"",
      longitude:"",
      formatted_address:"",
      updated_latitude:"",
      updated_longitude:"",
      country_name:[],
      is_alpha_numeric:"",
      pincode_length:0,
      is_business_model:0,
      country_code:'',
      country_id:"",
      countries_name: [],
      use_google_map :1,
      noLocation: true,
			coordinates: {},
			location_details: {
				lat: "",
				lng: "",
				address: "",
			},
      addressObjectMounted : '',
      zoomProperty : 18,
      disabled:null
    };
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
    if(localStorage.getItem("location_details") != null && localStorage.getItem("location_details") != undefined)
      this.addressObjectMounted = JSON.parse(localStorage.getItem("location_details")).address;
    this.use_google_map = parseFloat(localStorage.getItem("use_google_map"));
    if(this.use_google_map == 1)
    {
      this.getLocation();
    }
    this.getUserAddress();
    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0){
        this.country_filter_code = JSON.parse(data).country;
        this.statebody.country = JSON.parse(data).country;
        this.is_business_model = JSON.parse(data).business_model;
        this.is_alpha_numeric = JSON.parse(data).allow_alphanumeric_pincode;
        this.pincode_length = JSON.parse(data).pincode_length;
        this.use_google_map = JSON.parse(data).use_google_map;
        this.country_name = JSON.parse(data).country_codes;
        this.country_name = this.country_name.split(",");
      } else{
        this.country_filter_code = JSON.parse(data).country;
        this.statebody.country = JSON.parse(data).country;
        this.is_business_model = localconfig.business_model;  
      }
    })
    this.getUserAddress();
    this.showGMap();
    this.addtextColor = localStorage.getItem("button_default_color");
    this.originalColor();
    
    window.$('<style>.address-form .radio-dt input[type=radio]:checked ~ label { color: '+localStorage.getItem("css_button_customer_default_font")+'; }</style>').appendTo('body');
    window.$('<style>.address-form .radio-dt input[type=radio]:checked ~ label { background-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body');
    window.$('<style>.address-form .radio-dt label:hover { background-color: '+localStorage.getItem("button_hover_color")+' }</style>').appendTo('body');
    window.$('<style>.address-form .radio-dt label:hover { color: '+localStorage.getItem("css_button_customer_hover_font")+' }</style>').appendTo('body');
    window.$('<style>.address-form .form-group.checkbox label:before { border-top-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.address-form .form-group.checkbox label:before { border-right-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.address-form .form-group.checkbox label:before { border-bottom-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.address-form .form-group.checkbox label:before { border-left-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.address-form .form-group.checkbox input:checked + label:after { background-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
  },
  updated(){
		window.$(document).ready(function(){
			window.$('.location_icon svg path').css({fill: localStorage.getItem("button_default_color")})
		})
	},
  created(){
    if(window.$(".profile-section .tabs .nav-link").attr("aria-selected") == "true"){
      window.$('<style>.profile-section .tabs .nav-link.active { color: '+localStorage.getItem("css_button_customer_default_font")+'; }</style>').appendTo('body');
      window.$('<style>.profile-section .tabs .nav-link.active { background-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body');
    }
  },
  methods: {
    containsNumber(str) {
      return /[0-9]/.test(str);
    },
    closeAddAddress(){
      window.location.reload();
    },
   openAddAddressPopup(){
    if(this.use_google_map == 1)
    {
      this.getLocation();
    }
    this.location_type = 0;
    this.other_location = false;
    window.$('#address-pop-up').modal('show')
   },
   async getLocation() {
			try {
				await this.$getLocation({
					enableHighAccuracy: true
				}).then(coordinates => {
          this.latitude = parseFloat(coordinates.lat);
          this.longitude = parseFloat(coordinates.lng);
          this.updated_latitude = parseFloat(coordinates.lat);
          this.updated_longitude = parseFloat(coordinates.lng);
          this.center.lat = parseFloat(coordinates.lat);
          this.center.lng = parseFloat(coordinates.lng);
          this.showGMap();
          this.getStreetAddressFrom(coordinates.lat,coordinates.lng,true)
				});

			} catch (error) {
         this.latitude = parseFloat(localStorage.getItem("default_location_lat"));
         this.longitude = parseFloat(localStorage.getItem("default_location_long"));
         this.updated_latitude = parseFloat(localStorage.getItem("default_location_lat"));
         this.updated_longitude = parseFloat(localStorage.getItem("default_location_long"));
         this.center.lat = parseFloat(localStorage.getItem("default_location_lat"));
         this.center.lng = parseFloat(localStorage.getItem("default_location_long"));
         this.getStreetAddressFrom(this.latitude,this.longitude,true)
			}
		},
    changeColorOnHover(){
      window.$('.address-book .address .delete-btn:hover, .address-book .address .delete-btn:focus').css("background-color",localStorage.getItem("button_hover_color"))
      window.$('.address-book .address .delete-btn:hover, .address-book .address .delete-btn:focus').css("color",localStorage.getItem("css_button_customer_hover_font"))
    },
    changeColorOnLeave(){
      window.$('.address .delete-btn').css("background-color","#fff")
      window.$('.address .delete-btn').css("color",localStorage.getItem("button_default_color"))
    },
    getAddressData(addressData) {
      this.isAutoCompleteDataSelected=true;
      this.$refs.addAddressMap.$mapPromise.then((map) => {
        map.setZoom(18)
      })
			this.latitude = addressData.latitude;
      this.longitude = addressData.longitude;
      this.updated_latitude = addressData.latitude;
      this.updated_longitude = addressData.longitude;
      window.$("#mapAddAddress").val(addressData.street_number+" "+addressData.route+", "+addressData.locality+", "+addressData.administrative_area_level_1+" "+addressData.postal_code+", "+addressData.country)
      this.address2 = window.$("#mapAddAddress").val();
      this.street = addressData.street_number+" "+addressData.route;
      this.zipcode1 = addressData.postal_code;
      this.country = addressData.country;
      this.state = addressData.administrative_area_level_1;
      this.city = addressData.locality;
      this.showGMap();
		},
    updateCoordinates(location) {
      this.updated_latitude = location.latLng.lat();
      this.updated_longitude = location.latLng.lng();
      this.getStreetAddressFrom(location.latLng.lat(),location.latLng.lng(),true)
    },
    async getStreetAddressFrom(lat, long,formatedFlag) {
      var _this=this;
      this.country_id = '';
      this.state_id = '';
      this.city_id = '';
      this.area_id = '';
      this.address2 = '';
      this.pincode = ''; 

			try {
				var {
					data
				} = await axios.get(
				"https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
				lat +
				"%2C" +
				long +
				"&key="+((localStorage.getItem("google_map_key")!=null && localStorage.getItem("google_map_key")!="" && localStorage.getItem("google_map_key")!=undefined) ? localStorage.getItem("google_map_key") : 'AIzaSyDU1yuRqsrT2TFdn5srqr5F9iDzdDqzVL4')
				);
				if (data.error_message) {
					console.log(data.error_message)
				} else {
              if(formatedFlag){
                _this.formatted_address = data.results[0].formatted_address;
                window.$('.pac-target-input').val(data.results[0].formatted_address)
                window.$("#mapAddAddress").val(data.results[0].formatted_address)
              }

              let returnData = {};
              const ADDRESS_COMPONENTS = {
                  subpremise : 'short_name',
                  street_number: 'short_name',
                  route: 'long_name',
                  locality: 'long_name',
                  administrative_area_level_1: 'long_name',
                  administrative_area_level_2: 'long_name',
                  country: 'long_name',
                  postal_code: 'short_name'
              };
              
              for (let i = 0; i < data.results[0].address_components.length; i++) {
                  let addressType = data.results[0].address_components[i].types[0];

                  if (ADDRESS_COMPONENTS[addressType]) {
                      let val = data.results[0].address_components[i][ADDRESS_COMPONENTS[addressType]];
                      returnData[addressType] = val;
                  }
              }
              this.address2 = window.$("#mapAddAddress").val();
              this.street = returnData.street_number+" "+returnData.route;
              this.zipcode1 = returnData.postal_code;
              this.country = returnData.country;
              this.state = returnData.administrative_area_level_1;
              this.city = returnData.locality;
				}
			} catch (error) {
				console.log(error);
			}
		},
    async showGMap() {
      this.showModal = true;
      const marker = { lat: parseFloat(this.latitude), lng: parseFloat(this.longitude) };
      this.markers =[];
      this.markers.push({ position: marker });
      this.center = marker;
    },
    getUserAddress() {
      this.loading = true;
      this.profile.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.$store.dispatch("customeraddress", this.profile).then(response => {
        this.getaddressstatus = response.status;
        this.getaddressdetails = response;
        this.loading = false;
      });
    },
    async getCountries(){
      this.countries = this.country_filter_code;
      this.countries_name = null;
      this.$store.dispatch("getcountries", this.countries).then(response => {
        this.countries_name = JSON.parse(JSON.stringify(response.data));
      });
    },
    getStates() {
      this.statebody.country = this.country_id;
      this.states = null;
      this.$store.dispatch("getstates", this.statebody).then(response => {
        if (response.status == 1){
          this.states = JSON.parse(JSON.stringify(response.data));
          this.state_id= ""; 
          this.city_id = "";
          this.area_id="";
        }
      });
    },
    getCities(){
      this.citybody.state = this.state_id
      this.cities = null;
      this.$store.dispatch("getcities", this.citybody).then(response => {
        if(response.status == 1){
          this.cities = JSON.parse(JSON.stringify(response.data));
        }
      })
    },
    getAreas() {
      this.areabody.city = this.city_id;
      this.areas = null;
      this.$store.dispatch("getarealist", this.areabody).then(response => {
        if (response.status == 1)
        this.areas = JSON.parse(JSON.stringify(response.data));
      });
    },
    addAddress() {
      if (!((this.zipcode1 != null && this.zipcode1.indexOf('undefined') <= -1) && (this.city != null && this.city.indexOf('undefined') <= -1) && (this.state != null && this.state.indexOf('undefined') <= -1) && (this.country != null && this.country.indexOf('undefined') <= -1) && (this.street != null && this.street.indexOf('undefined') <= -1) )){
        this.$toast.error("Select valid address with Street number Name, City, State and Country.")
        return false;
      }
      this.enablesavesucadrsmesg = true;
      this.$validator.validate()
      this.$validator.validateAll().then(result => {
        if (result && this.isAutoCompleteDataSelected) {
          this.customer_address.user_id = this.userprofile != null ? this.userprofile.user_id : "0";
          this.customer_address.is_default = this.default_address == true ? "1" : "0";

          if (this.location_type == "2" && this.tag != "") {
            this.customer_address.alias = this.tag;
          } else {
            this.customer_address.alias = this.location_types[this.location_type].val;
          }

          this.customer_address.alias_code = this.location_type;
          this.customer_address.address1 = this.address;
          this.customer_address.address2 = this.address2;
          this.customer_address.country = this.country;
          this.customer_address.state = this.state;
          this.customer_address.city = this.city;
          this.customer_address.zipcode1 = this.zipcode1;
          this.customer_address.street = this.street;
          this.customer_address.remarks = "";
          this.customer_address.latitude = this.updated_latitude;
          this.customer_address.longitude = this.updated_longitude;
          this.$store
            .dispatch("managecustomeraddrs", this.customer_address)
            .then(response => {
              if (JSON.parse(JSON.stringify(response)).status == "1") {
                this.enablesavesucadrsmesg = true;
                this.getsaveaddressmsg = JSON.parse(
                  JSON.stringify(response)
                ).message;
                setTimeout(() => { this.enablesavesucadrsmesg = false}, 1000);
                this.getUserAddress();
                this.address = "";
                this.address2 = "";
                this.state_id = "";
                this.city_id = "";
                this.area_id = "";
                this.pincode = null;
                this.default_address = false;
                this.other_location = false;
                this.tag = "";
                this.$validator.reset();
                window.$("#address-pop-up").modal("hide");
                this.$toast.success("Address added successfully");
              } else {
                this.enableerradrsmessage = true;
                this.geterraddrssmsg = JSON.parse(
                  JSON.stringify(response)
                ).message;
                setTimeout(() => (this.enableerradrsmessage = false), 3000);
              }
            });
        }
        else{
          this.enablesavesucadrsmesg=false;
          this.$toast.error("Select address from autocomplete");
        }
      });
    },
    removeAddress(address,address_id, is_default) {
      if(is_default == 1){
        this.$toast.error("Default Address cannot be deleted", { timeout: 10000 });
        return false;
      }
      else{
        this.remove_customer_address.user_id = this.userprofile != null ? this.userprofile.user_id : "0";
        this.remove_customer_address.customer_address_id = address_id;
        this.remove_customer_address.is_deleted = "1";
        this.remove_customer_address.alias_code = this.location_type;
        this.remove_customer_address.alias = address.alias;
        this.remove_customer_address.address1 = address.address;
        this.remove_customer_address.address2 = address.address2;
        this.remove_customer_address.country = address.country_name1;
        this.remove_customer_address.state = address.state_name1;
        this.remove_customer_address.city = address.city_name1;
        this.remove_customer_address.zipcode1 = address.zipcode1;
        this.remove_customer_address.remarks = address.remarks;
        this.remove_customer_address.latitude = address.lat;
        this.remove_customer_address.longitude = address.long;
        this.loading = true;
        this.$store
          .dispatch("deletecustomeraddrs", this.remove_customer_address)
          .then(response => {
            this.getUserAddress();
            this.enablesucadrsmessage = false;
            this.successadrsmessage = JSON.parse(JSON.stringify(response)).message;
            this.$toast.success("Address deleted successfully");
            this.loading = false;
          });
      }
    },
    setDeliveryAddress(address,customer_address_id) {
      this.loading = true;

      this.customer_address.user_id = this.userprofile != null ? this.userprofile.user_id : "0";
      this.customer_address.customer_address_id = customer_address_id;
      this.customer_address.is_default = "1";
      this.customer_address.alias_code = this.location_type;
      this.customer_address.alias = address.alias;
      this.customer_address.address1 = address.address;
      this.customer_address.address2 = address.address2;
      this.customer_address.country = address.country_name1;
      this.customer_address.state = address.state_name1;
      this.customer_address.city = address.city_name1;
      this.customer_address.zipcode1 = address.zipcode1;
      this.customer_address.remarks = address.remarks;
      this.customer_address.latitude = address.lat;
      this.customer_address.longitude = address.long;

      this.$store
        .dispatch("managecustomeraddrs", this.customer_address)
        .then(response => {
          this.getUserAddress();
          this.$toast.success("Default address set successfully");
          bus.$emit('setDefaultAddress', this.customer_address);
          this.enablesucadrsmessage = true;
          this.successadrsmessage = JSON.parse(
            JSON.stringify(response)            
          ).message;
          
          var _this = this;
          setTimeout(function(){
            this.enablesucadrsmessage = false;

            _this.location_details.lat = address.lat;
            _this.location_details.lng = address.long;
            _this.location_details.address = address.address2;
            _this.location_details.customer_address_id = customer_address_id;
            localStorage.setItem("location_details",JSON.stringify(_this.location_details));
            window.location.reload();

          }, 3000);
          
          this.loading = false;
        });
    },
    editAddress(valid) {
      window.$("#address-pop-up" + valid).modal("show");
    },
    selectLocationType(location_id) {
      if (location_id == 2) {
        this.other_location = true;
      } else {
        this.other_location = false;
      }
    },
    updateAddress() {
      this.getUserAddress();
      this.enableupdtadrsmessage = true;
      this.updtsuccessadrsmsg = "Customer addresses updated successfully";
      setTimeout(() => (this.enableupdtadrsmessage = false), 3000);
    },
  }
};
</script>