<template>
  <div>
      <section class="wallet">
        <!-- walet transaction popup -->
        <div class="modal fade" id="transaction-details-popup" tabindex="-1" role="dialog" aria-labelledby="transaction-details-label" aria-hidden="true">
          <div class="modal-dialog modal-lg " role="document">
            <div class="modal-content">

              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="modal-body" v-if="wallet_detail_object != null">
              <h2 class="section-heading">Wallet Details</h2>
              <div class="wallet-transactions">
                <h2 class="section-heading main-price "><span>&#8377;</span>{{wallet_detail_object.amount}}</h2>
                  <div class="row mx-0 align-items-center">
                    <div class="order-details">
                      <h5 class="order-number">{{wallet_detail_object.transaction_no}}</h5>
                      <h6 class="order-date-time">
                        <span class="date">{{wallet_detail_object.created_on}}</span>
                      </h6>
                    </div>
                    <div class="transaction-price">
                      <h5 class="main-price media align-items-sm-center justify-content-end">
                        <span>₹</span><span v-html="wallet_detail_object.amount"></span> 
                        <span class="icon">
                          <img v-if="wallet_detail_object.is_credit == 1" src="../../assets/images/svgs/credit.svg" alt="credit">
                          <img v-else src="../../assets/images/svgs/transfer.svg" alt="transfer">
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div class="paymetn-description">
                    <p>{{wallet_detail_object.remarks}}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div> 
        <!-- transfer amout popup -->
        <div class="modal fade wallet-transactions-popup" id="transfer-amt-popup" tabindex="-1" role="dialog" aria-labelledby="transfer-amt-label" aria-hidden="true">
          <div class="modal-dialog modal-lg " role="document">
            <div class="modal-content">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="modal-body">
                <div class="content-view active" id="tranformForm">
                <form
                  class="form-horizontal animated-form"
                  method="post"
                  id="wallettransfer"
                  @submit.prevent="WalletTransfer"
                  enctype="multipart/form-data"
                >        
                  <h2 class="section-heading">Transfer To User</h2>
                  <div class="bottom-section">
                      <div class="form-row ">
                        <div class="form-group col-12">
                            <label for="registeredmobilenumber" :style="textcolor" class="control-label">Registered Mobile Number*</label>
                            <input type="number" v-model="userWalletTransfer.userCreditWallet_phonenumber"
                              id="registeredmobilenumber"
                              name="registeredmobilenumber"
                              v-validate="'required||digits:10'" 
                              data-vv-scope="wallettransfer"
                              data-vv-rules="required"
                              data-vv-as="registeredmobilenumber"
                              onKeyPress="if(this.value.length == 10) return false"
                              @change="checkUserRegistration"
                              :class="{error: errors.items.filter(x=>x.field == 'registeredmobilenumber').length > 0}"
                              class="form-control"
                              maxlength="10"
                              >
                            <span
                              v-show="errors.items.filter(x=>x.field == 'registeredmobilenumber').length > 0"
                              class="help is-danger"
                            >Enter the recipient's mobile number</span>
                        </div>
                      </div>
                      <div class="form-row ">
                        <div class="form-group col-12">
                            <label for="TransferDetails" :style="textcolor" class="control-label">&#8377; Wallet Transfer Amount*</label>
                            <input type="number" v-model="userWalletTransfer.amount"
                              id="wallettransferamount"
                              name="wallettransferamount"
                              v-validate="'required'"                       
                              data-vv-scope="wallettransfer"
                              data-vv-rules="required" 
                              data-vv-as="wallettransferamount"
                              @keyup="valuePrecise()"
                              step="any"
                              min="0"
                              :class="{error: errors.items.filter(x=>x.field == 'wallettransferamount').length > 0}"
                              class="form-control"
                            >
                            <small id="transferHelp" class="form-text text-muted mt-2">Max wallet transfer allowed for <span>&#8377;</span>{{max_wallet_transfer_allow}}</small>
                            <span
                              v-show="errors.items.filter(x=>x.field == 'wallettransferamount').length > 0"
                              class="help is-danger"
                            >Enter wallet transfer amount</span>

                        </div>
                      </div>

                  </div>
                  <h3 class="mt-3">Wallet Transfer Charges 
                    {{currency_symbol}}
                    <span v-if="transfer_charge_type == 1">
                      {{(parseFloat(((userWalletTransfer.amount != ''? parseFloat(userWalletTransfer.amount) : 0) / 100) * transfer_charge)).toFixed(2)}}
                    </span>
                    <span v-else>
                      {{(parseFloat(transfer_charge)).toFixed(2)}}
                    </span>
                  </h3>
                    <p class="mt-1 small">Total Amount to be Debited <span>&#8377;</span>

                    <span v-if="transfer_charge_type == 1">
                      {{(parseFloat(userWalletTransfer.amount != ''? parseFloat(userWalletTransfer.amount) : 0) + ((parseFloat(((userWalletTransfer.amount != ''? parseFloat(userWalletTransfer.amount) : 0) / 100) * transfer_charge)))).toFixed(2)}}
                    </span>
                    <span v-else>
                      {{ userWalletTransfer.amount!='' ? (parseFloat(userWalletTransfer.amount) + parseFloat(transfer_charge)).toFixed(2) : parseFloat(0).toFixed(2)}}
                    </span>
                    </p>
                  <div class="row mx-0 justify-content-center button-row">    
                      <button class="btn left-button re-ordered" :style="cancelReOrderButton" data-dismiss="modal" aria-label="Close">Cancel</button>
                      <button type="submit" :style="colorObj" class="btn repeat-btn hoverEffect">Transfer</button>
                  </div>
                  </form>         
                  </div>
                <!-- Transfer To User CONFORMATION -->
                  <div class="content-view" id="transfeConfirm">
                    <h2 class="section-heading">Transfer To User</h2>
                    <div class="media my-profile-wallettransfer  order-profile align-items-center">
                      <div class="profile-pic mr-3">
                        <img v-if="to_userprofile != null && to_userprofile.user_picture != ''" :src=to_userprofile.user_picture alt="Image">
                        <avatar
                          :color="avatarBackgroundColor"
                          :fullname="to_userprofile.name"
                          :size="80"
                          v-if="to_userprofile != null && to_userprofile.user_picture==''"
                        ></avatar>
                      </div>
                      <div class="media-body">
                        <p class="order-status mb-0" v-if="to_userprofile != null">Confirm Transfer to <b>{{to_userprofile.name}} ?</b> </p>
                        {{userWalletTransfer.userCreditWallet_phonenumber}}
                      </div>
                    </div>
                    <ul class="list-unstyled transfer-details">
                      <li>
                        <div class="transfer-label">
                          <h5>Transfer Amount</h5>
                        </div>
                        <div class="transaction-price">
                          <h5><span>&#8377;</span>{{parseFloat(userWalletTransfer.amount).toFixed(2)}}</h5>
                        </div>
                      </li> 
                      <li>
                        <div class="transfer-label">
                          <h5>Transfer Charges</h5>
                        </div>
                        <div class="transaction-price">
                          <h5><span>&#8377;</span>{{parseFloat(transfer_charge_type == 1 ? ((userWalletTransfer.amount / 100) * transfer_charge) : (transfer_charge)).toFixed(2)}}</h5> 
                        </div>
                      </li> 
                    </ul> 
                    <div class="row mx-0 justify-content-center button-row">    
                      <button class="btn repeat-btn left-button re-ordered" :style="cancelReOrderButton" @click="goBackModalScreen()" id="Canceltransection">Cancel</button>
                      <button :style="colorObj" class="btn repeat-btn hoverEffect" @click="paymentConfirmTransfer()"  id="btnProceed">Proceed</button>
                    </div>
                  </div>

                  <!-- success transfer -->
                  <div class="content-view" id="transferSuccess">
                    <h2 class="section-heading">Successfully Transferred to User</h2>
                    <div class="transfer-content text-center">
                        <h4 class="h4 gm-medium" v-if="wallet_transfer_payment_response != null">{{wallet_transfer_payment_response.data.transaction_no}}</h4>
                        <div class="transfer-status">
                          <img src="../../assets/images/svgs/right-sign.svg" alt="right-sign">
                        </div>
                        <h3>Wallet Transferred</h3>
                        <p class="message" v-if="wallet_transfer_payment_response != null">{{wallet_transfer_payment_response.message}}</p>
                      </div>
                      <div class="row mx-0 justify-content-center button-row">    
                        <button :style="colorObj" class="btn repeat-btn hoverEffect" id="gotowallet" @click="goToWallet()" aria-label="Close">Go to Wallet</button>
                      </div>
                    </div>

                    <!-- cancel transfer -->
                  <div class="content-view" id="transferCancel">
                    <h2 class="section-heading">Transfer Cancelled to User</h2>
                    <div class="transfer-content text-center">
                        <h4 class="h4 gm-medium" v-if="wallet_transfer_payment_response != null">{{wallet_transfer_payment_response.data.transaction_no}}</h4>
                        <div class="transfer-status">
                          <img src="../../assets/images/svgs/right-sign.svg" alt="right-sign">
                        </div>
                        <h3>Transfer Failed!</h3>
                        <p class="message">Your Wallet Transfer has failed. You can go back to Wallet and try the transfer again.</p>
                      </div>
                      <div class="row mx-0 justify-content-center button-row">    
                        <button :style="colorObj" class="btn repeat-btn hoverEffect" id="gotowallet" data-dismiss="modal" aria-label="Close">Go to Wallet</button>
                      </div>
                    </div>
          
              </div>
            </div>
          </div>
        </div> 
        <!-- credit amout popup -->
        <div class="modal fade wallet-transactions-popup" id="credit-amt-popup" tabindex="-1" role="dialog" aria-labelledby="credit-amt-label" aria-hidden="true">
          <div class="modal-dialog modal-lg " role="document">
            <div class="modal-content">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="modal-body">
                <div class="content-view active" id="CreditForm">
                <form class="form-horizontal animated-form wallet-credit-form"           
                  method="post"
                  id="walletcredit"
                  @submit.prevent="walletCredit"
                  enctype="multipart/form-data"
                >

                <h2 class="section-heading">Credit Your Wallet</h2>
                <div class="bottom-section">

                    <div class="form-row">
                      <div class="form-group col-12">
                        <label for="crediteddetails" :style="textcolor" class="control-label">&#8377; Wallet Credit Amount*</label>
                        <input type="number"  
                          v-model="userCreditWallet.amount" 
                          id="walletcreditamount"
                          name="walletcreditamount"
                          v-validate="'required'" 
                          data-vv-scope="walletcreditForm"
                          data-vv-rules="required" 
                          data-vv-as="walletcreditamount"
                          min="0"
                          step="any"
                          :class="{error: errors.items.filter(x=>x.field == 'walletcreditamount').length > 0}"
                          class="form-control"
                          @keyup="valuePreciseCredit()"
                        >
                        <small id="labelHelp" class="mt-2 form-text text-muted">Min wallet credit for <span>&#8377;</span>{{min_credit}}</small>
                        <span v-show="errors.items.filter(x=>x.field == 'walletcreditamount').length > 0" class="help is-danger">Enter wallet credit amount</span>
                        
                    </div>
                    <div v-if="payment_method_type == 4" class="form-group col-md-6">
                          <label :style="textcolor" for="ccname">Name on card</label>
                          <input 
                            type="text" 
                            class="form-control" 
                            :class="{error: errors.has('gift_form.ccname')}" 
                            id="ccname" 
                            placeholder="Name on card" 
                            name="ccname" 
                            data-vv-scope="gift_form_card"
                            v-model="userCreditWallet.ccname" 
                            v-validate="'required'">
                          <span v-show="errors.has('gift_form.ccname')" class="help is-danger">Name on card is required</span>
                    </div>
                    <div v-if="payment_method_type == 4" class="form-group col-md-6">
                          <label :style="textcolor" for="ccnumber">Credit card number</label>
                          <input 
                            type="text" 
                            class="form-control" 
                            :class="{error: errors.has('gift_form.ccnumber')}" 
                            id="ccnumber" 
                            placeholder="Credit card number" 
                            name="ccnumber" 
                            data-vv-scope="gift_form_card"
                            v-model="userCreditWallet.ccnumber" 
                            v-validate="'required'"
                            v-cardformat:formatCardNumber
                            maxlength="19">
                          <span v-show="errors.has('gift_form.ccnumber')" class="help is-danger">Credit card number is required</span>
                    </div>
                    <div v-if="payment_method_type == 4" class="form-group col-md-6">
                          <label :style="textcolor" for="ccexpiration">Expiration</label>
                            <input 
                              type="text" 
                              class="form-control" 
                              :class="{error: errors.has('gift_form.ccexpiration')}" 
                              id="ccexpiration" 
                              placeholder="MM / YYYY" 
                              name="ccexpiration" 
                              data-vv-scope="gift_form_card"
                              v-model="userCreditWallet.ccexpiration" 
                              v-validate="'required'"
                              v-cardformat:formatCardExpiry>
                          <span v-show="errors.has('gift_form.ccexpiration')" class="help is-danger">Expiration is required</span>
                    </div>
                    <div v-if="payment_method_type == 4" class="form-group col-md-6">
                          <label :style="textcolor" for="cccvv">CVV</label>
                          <input 
                            type="text" 
                            class="form-control" 
                            :class="{error: errors.has('gift_form.cccvv')}" 
                            id="cccvv" 
                            placeholder="CVV" 
                            name="cccvv" 
                            data-vv-scope="gift_form_card"
                            v-model="userCreditWallet.cccvv" 
                            v-validate="'required'"
                            v-cardformat:formatCardCVC>
                            <span v-show="errors.has('gift_form.cccvv')" class="help is-danger">Security code is required</span>
                    </div>
                    </div>
                </div>
                <h3 class="mt-3">Wallet Credit Charges {{credit_charge_type == 1 ? (userCreditWallet.amount!='' ? currency_symbol + (parseFloat(userCreditWallet.amount * (credit_charge / 100))).toFixed(2) : currency_symbol+ parseFloat(0).toFixed(2)) : currency_symbol+' '+ credit_charge}}</h3>
                  <p class="mt-1 small">Net Wallet Credit Amount <span>&#8377;</span>
                    <span v-if="credit_charge_type == 1">
                        {{ parseFloat((parseFloat(userCreditWallet.amount != "" && userCreditWallet.amount != undefined ? parseFloat(userCreditWallet.amount).toFixed(2) : 0).toFixed(2) - parseFloat(parseFloat(userCreditWallet.amount != "" && userCreditWallet.amount != undefined ? parseFloat(userCreditWallet.amount).toFixed(2) : 0).toFixed(2) / 100).toFixed(2) * credit_charge) > 0 ? (parseFloat(userCreditWallet.amount != "" && userCreditWallet.amount != undefined ? parseFloat(userCreditWallet.amount).toFixed(2) : parseFloat(0).toFixed(2)).toFixed(2) - parseFloat(parseFloat(userCreditWallet.amount != "" && userCreditWallet.amount != undefined ? userCreditWallet.amount : 0) / 100).toFixed(2) * credit_charge) : parseFloat(0).toFixed(2)).toFixed(2) }}
                    </span>
                    <span v-else>
                      {{(parseFloat(userCreditWallet.amount != "" && userCreditWallet.amount != undefined ? userCreditWallet.amount : 0) - credit_charge) > 0 ? (parseFloat(userCreditWallet.amount != "" && userCreditWallet.amount != undefined ? userCreditWallet.amount : 0) - credit_charge) : 0}}
                    </span>
                  </p>
                  <div class="row mx-0 justify-content-center button-row">    
                    <span class="btn left-button re-ordered" data-dismiss="modal" aria-label="Close" :style="cancelReOrderButton" id="CreditFail">Cancel</span>
                    <button type="submit" :style="colorObj" class="btn repeat-btn hoverEffect"  id="paycredit">Pay</button>
                  </div>
                </form>
                </div>

                  <!-- success transfer -->
                  <div class="content-view" id="credittransferSuccess">
                    <h2 class="section-heading">Wallet Credit Successful</h2>
                    <div class="transfer-content text-center">
                        <h4 class="h4 gm-medium" v-if="wallet_transfer_credit_response != null">{{wallet_transfer_credit_response.transaction_no}}</h4>
                        <div class="transfer-status">
                          <img src="../../assets/images/svgs/right-sign.svg" alt="right-sign">
                        </div>
                        <h3>Payment Success!</h3>
                        <p class="message" v-if="wallet_transfer_credit_response != null">
                          {{wallet_transfer_credit_response.message}}
                        </p>
                      </div>
                      <div class="row mx-0 justify-content-center button-row">    
                        <button :style="colorObj" class="btn repeat-btn hoverEffect" id="gotowallet" data-dismiss="modal" aria-label="Close">Go to Wallet</button>
                      </div>
                    </div>

                    <!-- cancel transfer -->
                  <div class="content-view" id="credittransferCancel">
                    <h2 class="section-heading">Wallet Credit Failed</h2>
                    <div class="transfer-content text-center">
                        <h4 class="h4 gm-medium" v-if="wallet_transfer_credit_response != null">{{wallet_transfer_credit_response.transaction_no}}</h4>
                        <div class="transfer-status">
                          <img src="../../assets/images/svgs/right-sign.svg" alt="right-sign">
                        </div>
                        <h3>Payment Failed!</h3>
                        <p class="message">Your Payment has failed. You can go back to Wallet and try the payment again.</p>
                      </div>
                      <div class="row mx-0 justify-content-center button-row">    
                        <button :style="colorObj" class="btn repeat-btn hoverEffect" id="gotowallet" data-dismiss="modal" aria-label="Close">Go to Wallet</button>
                      </div>
                    </div>

              </div>
            </div>
          </div>
        </div> 


        <div class="container">
          <div class="price row mx-0 mt-3 align-items-center justify-content-between" v-if="loading == false">
            <h2 class="section-heading">Wallet</h2>
            <!-- {{getwallet.data}} -->
            <h2 class="section-heading main-price">
              Total Balance&nbsp;:&nbsp;<span>&#8377;</span><span v-html="priceFormatter(((getwallet.data != null && getwallet.data != undefined) ? getwallet.data.balance : 0))"></span>
              <img class="empty-wallet-image" src="../../assets/images/profile-icons/ic_my_wallet.svg" alt="empty-box">
            </h2>
          </div>
          <ul class="transfer-credit row mx-0 mt-0 list-unstyled align-items-center justify-content-end">
            <li v-if="is_transfer_chargable == 1" class="transfer media align-items-center" @click="openWalletTransfer()" >
              Transfer
              <span class="icon"><img src="../../assets/images/svgs/transfer.svg" alt="transfer"></span>
            </li>
            <li v-if="allow_credit == 1" class="credit media align-items-center" @click="openWalletCredit()">
              Credit
              <span class="icon"><img src="../../assets/images/svgs/credit.svg" alt="credit"></span>
            </li>
          </ul>
          <div class="basket-order wallet-tab col-lg-12 mt-4 mt-lg-0" v-if="pageCount == 0 && loading == false">
            <div class="table-responsive basket-list">
              <div class="empty-box text-center">
                <div class="contents">
                  <div class="empty-image">
                    <img src="../../assets/images/profile-icons/ic_my_wallet.svg" alt="empty-box">
                  </div>
                <h3>No Transaction Found</h3>
                </div>
              </div>
            </div>
          </div>
          <ul class="notifications-listing list-unstyled mt-4" v-if="pageCount!=0 && loading == false">
            <div
              class="alert alert-success"
              role="alert"
              v-show="enablenotfctnsucmessage"
            >{{ successnotfctnmessage}}</div>
            
        <li 
          v-for="wallet in paginatedData"
          v-bind:key="wallet.transaction_no"
          data-toggle="modal" data-target="#transaction-details-popup" @click="openTransactionDetail(wallet)">
          <div class="order-details">
           <h5 class="order-number">{{wallet.transaction_no}}</h5>
           <h6 class="order-date-time">
            <span class="date">{{wallet.created_on}}</span>
          </h6>
          </div>
          <div class="transaction-price">
            <h5 class="main-price media align-items-sm-center justify-content-end">
              <span>&#8377;</span>
              <span v-html="priceFormatter(wallet.amount)"></span>
              <span class="icon">
                <img v-if="wallet.is_credit == 1" src="../../assets/images/svgs/credit.svg" alt="credit">
                <img v-else src="../../assets/images/svgs/transfer.svg" alt="transfer">
              </span>
            </h5>
          </div>
        </li>
          </ul>
          <p class="text-center" v-if="pageCount!=0">
            <button :disabled="pageNumber === 0" @click="prevPage" :style="colorObj" class="btn button-style hoverEffect">Previous</button>
            &nbsp;
            <button
              :disabled="pageNumber >= pageCount - 1"
              @click="nextPage"
              :style="colorObj"
              class="btn button-style hoverEffect"
            >Next</button>
          </p>
        </div>
      </section>
      <div class="container" v-if="loading==true">
        <p class="text-center">
          <img src="../../assets/images/loading.gif" />
        </p>
      </div>
  </div>
</template>
<script>
import Avatar from "vue-avatar-component";
import { bus } from "../../main";
import helper from "../../store/helper";
export default {
  name: "Wallet",
  components: {
    Avatar
  },
  mixins:[helper],
  data() {
    return {
      loading: true,
      currency_symbol:'',
      avatarBackgroundColor:"",      
      profile: {
        user_id: ""
      },
      userProfile:[],
      userWalletTransfer:{
        user_id:"",
        to_user_id:"",
        amount:'',
        userCreditWallet_phonenumber:""
      },
      userCreditWallet:{
        user_id:"",
        amount:'',
        ccname:"",
        ccnumber:"",
        ccexpiration:"",
        cccvv:""
      },
      userwallet: {
        user_id: "",
        page:""
      },
      userCreditWallet_phonenumber:"",
      userreadnotfctn: {
        user_id: "",
        notification_id: "",
        is_read: ""
      },
      userprofile: null,
      to_userprofile: null,
      user_address: "",
      loctntype: "",
      cityname: "",
      enablesucmessage: false,
      successmessage: "",

      successadrsmessage: "",
      enablesucsaveadrsmsg: false,
      successsaveadrsmsg: "",
      enableerrmessage: false,
      errormessage: "",

      customerprofile: {
        user_id: "",
        name: "",
        phone: "",
        email: ""
      },
      image: "",
      profiledata: [],
      prflename: "",
      prfldata: [],
      prflephoneno: "",
      getwallet: [],
      prfleemail: "",
      prflepasswrd: null,
      prfleconfrmpass: null,
      prfleimage: "",
      user_picture: "",
      cities: [],
      size: 5,
      getarea: "",
      enablenotfctnsucmessage: false,
      successnotfctnmessage: "",
      pageNumber: 0,
      pageOfItems: [],
      passwordFieldType: "password",
      getprfleimage: "",
      confirmpasswordFieldType: "password",
      max_wallet_transfer_allow : 0,
      transfer_charge : 0,
      transfer_charge_type : 1,
      is_transfer_chargable : 1,
      is_credit_chargable : 1,
      credit_charge_type: 1,
      credit_charge : 0,
      allow_credit  : 1,
      wallet_transfer_payment_response : null,
      wallet_transfer_credit_response : null,
      min_credit : 0,
      wallet_detail_object : null,
      gift_voucher_data: {
        user_id: '',
        name: '',
        phone: '',
        amount: '',
        from_date: '',
        to_date: '',
        card_name: '',
        card_number: '',
        expiration_date: '',
        card_code: '',
        source: '1',
        billing_address_id:'',
        show_billing_address:false,
      },
      payment_method_type: 1,
      generatetransactionrequest : {
        user_id: "",
        payment_gateway_type: "",
        amount: "",
        call_back_url: "",
        source:1,
        card_number:'',
        expiration_date:'',
        card_code:'',
        card_name:'',
        billing_address_id:''
      },
      show_price_same_font:1
    };
  },
  updated(){
    this.avatarBackgroundColor = this.buttonColor.backgroundColor;
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
      this.user_address = this.getFilteredUserAddress(this.userprofile);
    } else {
      this.userprofile = null;
    }
    this.max_wallet_transfer_allow = parseFloat(localStorage.getItem("max_wallet_transfer_allow"));
    this.transfer_charge = parseFloat(localStorage.getItem("transfer_charge"));
    this.transfer_charge_type = parseFloat(localStorage.getItem("transfer_charge_type"));
    this.is_transfer_chargable = parseFloat(localStorage.getItem("is_transfer_chargable"));
    this.is_credit_chargable = parseFloat(localStorage.getItem("is_credit_chargable"));
    this.credit_charge_type = parseFloat(localStorage.getItem("credit_charge_type"));
    this.credit_charge = parseFloat(localStorage.getItem("credit_charge"));
    this.allow_credit = parseFloat(localStorage.getItem("allow_credit"));
    this.min_credit = parseFloat(localStorage.getItem("min_credit"));
    this.currency_symbol = localStorage.getItem("currency_symbol");
    this.getProfile();
    this.getWallet();
    this.getUserAddress();
    bus.$on("setDefaultAddress", () => {
      this.getUserAddress();
    });
    this.originalColor();
    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0){
        this.show_price_same_font = JSON.parse(data).show_price_same_font;
      }
    });
    window.$(document).ready(function() {
      window.$('.form-control').focus(function(){
        window.$(this).parent().addClass('active');
      });
      window.$('.form-control').blur(function(){
        if (!window.$(this).val())
          window.$(this).parent().removeClass('active');
      });
    });

    this.payment_method_type = localStorage.getItem("payment_gateway_type");
    
    if (localStorage.getItem("current_url_wallet") != null && localStorage.getItem("current_url_wallet") != undefined
     && localStorage.getItem("current_url_wallet").indexOf('message') > -1 && localStorage.getItem("current_url_wallet").indexOf('#WalletCredit') > -1)
    {
      window.$('#credittransferCancel').show()
      window.$('#CreditForm').hide()
      window.$('#credit-amt-popup').modal('show')
      localStorage.removeItem("current_url_wallet")
    }
    else if(localStorage.getItem("current_url_wallet") != null && localStorage.getItem("current_url_wallet") != undefined && localStorage.getItem("current_url_wallet").indexOf('result') > -1 && localStorage.getItem("current_url_wallet").indexOf('result=FAILED') <= -1 && localStorage.getItem("current_url_wallet").indexOf('#WalletCredit') > -1)
    {
      localStorage.removeItem("current_url_wallet")      
      this.saveWalletCredit()
    }

  },
  computed: {
    pageCount() {
      if (this.getwallet.status == "1") {
        let l = this.getwallet.data.transactions.length,
          s = this.size;
        return Math.ceil(l / s);
      } else {
        return 0;
      }
    },
    paginatedData() {
      if (this.getwallet.status == "1") {
        const start = this.pageNumber * this.size,
          end = start + this.size;
        if (this.getwallet.data.transactions != null)
            return this.getwallet.data.transactions.slice(start, end);
        else
            return this.getwallet.data.transactions;        
      } else {
        return 0;
      }
    }
  },
  methods: {
    priceFormatter(value) {
      if(value != ''){
        var price = value.toString().split(".")[0];
        var decimal = value.toString().split(".")[1];
        var decimal_font_size = this.show_price_same_font;
        var response = price;
        if(decimal != undefined){
          if(decimal_font_size == 1){
            response += "<span>." + decimal + "</span>";
          } else {
            response += "<span class='price-decimal'><small>." + decimal + "</small></span>";
          }
        } else {
          if(decimal_font_size == 1){
            response += "<span>.00</span>";
          } else {
            response += "<span class='price-decimal'><small>.00</small></span>";
          }
        }
        return response;
      } else {
        return '0';
      }
    },
    priceFormatterSmall(value) {
      if(value != '' && value){
        var price = value.toString().split(".")[0];
        var decimal = value.toString().split(".")[1];
        var response = price;
        if(decimal != undefined){
          response += "<span class='price-decimal'><small class='small-font'>." + decimal + "</small></span>";
        } else {
          response += "<span class='price-decimal'><small class='small-font'>.00</small></span>";
        }
        return response;
      } else {
        return '0';
      }
    },
    valuePrecise(){
      if (this.userWalletTransfer.amount != "" && parseFloat(this.userWalletTransfer.amount.split(".")[1]) > 99){
        this.userWalletTransfer.amount = parseFloat(this.userWalletTransfer.amount).toFixed(2);
        }
    },
    valuePreciseCredit(){
      if (this.userCreditWallet.amount != "" && parseFloat(this.userCreditWallet.amount.split(".")[1]) > 99){
        this.userCreditWallet.amount = parseFloat(this.userCreditWallet.amount).toFixed(2);
        }
    },
    openTransactionDetail(walletObject){
      this.wallet_detail_object = walletObject;
      window.$('#transaction-details-popup').modal('show')
    },
    openWalletCredit()
    {
      if (localStorage.getItem("current_url_wallet") != null && localStorage.getItem("current_url_wallet") != undefined && localStorage.getItem("current_url_wallet").split('#WalletCredit').length != undefined && localStorage.getItem("current_url_wallet").split('#WalletCredit').length > 1)
      {
        window.location.href=localStorage.getItem("current_url_wallet").split('#WalletCredit')[0]
        localStorage.removeItem("current_url_wallet")
      }
      window.$('#CreditForm').show()
      window.$('#credittransferSuccess').hide()
      window.$('#credittransferCancel').hide()

      window.$('#credit-amt-popup').modal('show')
      this.userCreditWallet.user_id = "";
      this.userCreditWallet.amount = '';
    },
    openWalletTransfer()
    {
      window.$('#tranformForm').show()
      window.$('#transfeConfirm').hide()
      window.$('#transferSuccess').hide()
      window.$('#transferCancel').hide()      

      window.$('#transfer-amt-popup').modal('show')
      this.userWalletTransfer.user_id="";
      this.userWalletTransfer.to_user_id="";
      this.userWalletTransfer.amount="";
      this.userWalletTransfer.userCreditWallet_phonenumber="";
    },
    goToWallet()
    {
      window.$('#transfer-amt-popup').modal('hide');
      this.userWalletTransfer.user_id="";
      this.userWalletTransfer.to_user_id="";
      this.userWalletTransfer.amount="";
      this.userWalletTransfer.userCreditWallet_phonenumber="";
    },
    paymentConfirmTransfer(){
      this.loading = true;
      if(this.userWalletTransfer.amount > parseFloat(localStorage.getItem("max_wallet_transfer_allow")))
      {
        this.$toast.error("Max wallet transfer allow is "+localStorage.getItem("max_wallet_transfer_allow"))
        return false;
      }
      this.userWalletTransfer.to_user_id = this.to_userprofile.user_id;
      this.userWalletTransfer.user_id = this.userprofile.user_id;
      this.$store.dispatch("customerwallettransfer", this.userWalletTransfer).then(response => {
        this.wallet_transfer_payment_response = response;
        if (JSON.parse(JSON.stringify(response)).status == "1") 
        {
          window.$('#transferSuccess').show();
          this.getWallet();
        }
        else
        {
          window.$('#transferCancel').show();
        }
        window.$('#transfeConfirm').hide();
        this.loading = false;
      })
    },
    goBackModalScreen(){
      window.$('#tranformForm').show()
      window.$('#transfeConfirm').hide()
    },
    async checkUserRegistration(){
      this.loading = true;
        this.$store.dispatch("getcustomerbymobilenumber", this.userWalletTransfer).then(response => {
            if (JSON.parse(JSON.stringify(response)).status != "1") {
              this.$toast.error("User not registered.");
            }
            this.loading = false;
        })
    },
    async WalletTransfer()
    {
      if(parseFloat(((this.getwallet.data != null && this.getwallet.data != undefined) ? parseFloat(this.getwallet.data.balance) : 0)) < parseFloat(this.transfer_charge_type == 1 ? ((this.userWalletTransfer.amount / 100) * this.transfer_charge) : (this.transfer_charge)) + parseFloat(this.userWalletTransfer.amount))
      {
        this.$toast.error("Your wallet balance is not sufficient")
        return false;
      }
      var _this=this;
      this.$validator.validateAll('wallettransfer').then(result => {
      if (result) {
        this.$store.dispatch("getcustomerbymobilenumber", this.userWalletTransfer).then(response => {
            if (JSON.parse(JSON.stringify(response)).status == "1") {

              var user_profile_id = (localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).user_id : "0";
              
              if (JSON.parse(JSON.stringify(response)).data.user_id == user_profile_id)
              {
                this.$toast.error("Self transfer is not allowed");
                return false;                
              }

              if (this.userWalletTransfer.amount <= 0){
                this.$toast.error("Enter Wallet Transfer Amount");
                return false;
              }
              if(this.userWalletTransfer.amount <= this.max_wallet_transfer_allow){
              _this.to_userprofile = response.data;
              window.$('#tranformForm').hide()
              window.$('#transfeConfirm').show()
              }
              else{
                this.$toast.error("Maximum Wallet Transfer Amount is "+(localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹")+""+this.max_wallet_transfer_allow+".");  
              }
            }
            else
            {
              this.$toast.error("User not registered.");
            }
        })
      }
      })
    },
    saveWalletCredit(){
      this.loading = true;
      if(localStorage.getItem("paymenttransactionid") != null && localStorage.getItem("paymenttransactionid") != undefined && localStorage.getItem("walletcreditamount") != null && localStorage.getItem("walletcreditamount") != undefined)
      {
      this.userCreditWallet.transaction_id = localStorage.getItem("paymenttransactionid");
      this.userCreditWallet.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.userCreditWallet.amount = localStorage.getItem("walletcreditamount");
      this.$store.dispatch("customerwalletcredit", this.userCreditWallet).then(response => {
        var result = JSON.parse(JSON.stringify(response));
        this.wallet_transfer_credit_response = result;
        if(result.status == 1)
        {
          window.$('#credittransferSuccess').show()
          this.wallet_transfer_credit_response.transaction_no = result.data.transaction_no;
        }
        else
        {
          window.$('#credittransferCancel').show()
        }
        
        this.wallet_transfer_credit_response.message = response.message

        localStorage.removeItem("walletcreditamount");
        localStorage.removeItem("paymenttransactionid");
        window.$('#CreditForm').hide()
        window.$('#credit-amt-popup').modal('show')
        this.getWallet();
        this.loading = false;
      });
      }
    },
    walletCredit(){
      if (this.userCreditWallet.amount < parseFloat(localStorage.getItem("min_credit")))
      {
         this.$toast.error("Minimum wallet credit is "+(localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹")+" "+localStorage.getItem("min_credit"))
         return false;
      }

      this.generatetransactionrequest.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.generatetransactionrequest.payment_gateway_type = this.payment_method_type;
      this.generatetransactionrequest.amount = this.userCreditWallet.amount;
      this.generatetransactionrequest.call_back_url=window.location.href+'#WalletCredit';
      this.generatetransactionrequest.source=1;

      this.generatetransactionrequest.card_name = this.userCreditWallet.ccname;
      this.generatetransactionrequest.card_number = this.userCreditWallet.ccnumber;
      this.generatetransactionrequest.expiration_date = this.userCreditWallet.ccexpiration;
      this.generatetransactionrequest.card_code = this.userCreditWallet.cccvv;

      this.generatetransactionrequest.billing_address_id = this.billing_address_id;
     
     var _this=this;

      this.$store
      .dispatch("generatetransactionrequest", this.generatetransactionrequest)
      .then(response => {
      if (response.status == 1) {
      if(_this.payment_method_type == 1 || _this.payment_method_type == 2 || _this.payment_method_type == 3)
      {
        localStorage.setItem("paymenttransactionid",response.data.transaction_id);
        localStorage.setItem("paymentmethodid",this.payment_method_type);
        localStorage.setItem("walletcreditresponsemessage",response.message);
        localStorage.setItem("walletcreditamount",this.userCreditWallet.amount);
        window.location.replace(response.data.transaction_url);
      }
      else
      {
      this.userCreditWallet.transaction_id = response.data.transaction_id;
      this.userCreditWallet.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.$store.dispatch("customerwalletcredit", this.userCreditWallet).then(response => {
        var result = JSON.parse(JSON.stringify(response));
        this.wallet_transfer_credit_response = result;
        if(result.status == 1)
        {
          window.$('#credittransferSuccess').show()
        }
        else
        {
          window.$('#credittransferCancel').show()
        }
        window.$('#CreditForm').hide()
      });
      }
      } else {
        this.$toast.error("Enter Wallet Credit Amount");
      }
      return false;
      })
    },
    getUserAddress() {
      this.loading = true;
      this.profile.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.$store.dispatch("customeraddress", this.profile).then(response => {
        var result = JSON.parse(JSON.stringify(response));
        if(result.status == 1){
          var default_address = this.getDefaultAddress(result.data);
          this.user_address = this.getFilteredUserAddress(default_address[0]);
        }
        this.loading = false;
      });
    },
    getDefaultAddress(user_address){
      return user_address.filter(
        function(elem) {
          if (elem.is_default == "1") return elem;
        }
      );
    },
    getFilteredUserAddress(user){
      var tmp_user_address = [];
      tmp_user_address.push(user.address, user.address2, user.area_name, user.city_name, user.zipcode);
      var filtered_user_address = tmp_user_address.filter(function (el) { return el; });
      
      if(filtered_user_address.length > 0){
        return filtered_user_address.join(', ');
      } else {
        return "";
      }
    },
    readNotifictn(notictnid, isreadsts) {
      if (isreadsts == "1") {
        this.loading = true;
        this.userreadnotfctn.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
        this.userreadnotfctn.notification_id = notictnid;
        this.userreadnotfctn.is_read = "0";
        this.$store
          .dispatch("getcustreadnotifictn", this.userreadnotfctn)
          .then(response => {
            this.enablenotfctnsucmessage = true;
            this.successnotfctnmessage = JSON.parse(
              JSON.stringify(response)
            ).message;
            setTimeout(() => (this.enablenotfctnsucmessage = false), 3000);
            this.getWallet();
            this.loading = false;
          });
      }
    },
    nextPage() {
      this.pageNumber++;
      this.getWallet();
    },
    prevPage() {
      this.pageNumber--;
      this.getwallet();
    },
    getWallet() {
      this.loading = true;
      this.userwallet.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.userwallet.page = this.pageNumber;
      this.$store
        .dispatch("getcustomerwallet", this.userwallet)
        .then(response => {
          this.getwallet = JSON.parse(JSON.stringify(response));
          this.loading = false;
        });
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    confirmswitchVisibility() {
      this.confirmpasswordFieldType =
        this.confirmpasswordFieldType === "password" ? "text" : "password";
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (
        files[0].type == "image/jpeg" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/png"
      ) {
        window.$(".fileuplderr").hide();
        this.createImage(files[0]);
        this.getprfleimage = files[0];
      } else {
        window.$(".fileuplderr").show();
      }
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = e => {
        this.getprfleimage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    getProfile() {
      this.loading = true;
      this.profile.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.$store.dispatch("profiledetails", this.profile).then(response => {
        if(response.status == 1)
        {
          this.profiledata = JSON.parse(JSON.stringify(response.data));
          this.prflename = this.profiledata.name;
          this.prflephoneno = this.profiledata.phone;
          this.prfleemail = this.profiledata.email;
          this.dbprfleimage = this.profiledata.user_picture;
          this.getprfleimage = this.profiledata.user_picture;
          this.prflepasswrd = null;
        }
        this.loading = false;
      });
    }
  }
};
</script>
<style>
.hide_file {
  background-color: #24c7fa;
  background-image: url("../../assets/images/svgs/photo-change.svg");
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}
.fileuplderr {
  display: none;
  margin: 1px 40px -1px -40px;
  width: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>