<template>
  <div
    class="modal fade address-popup edit-addressbook-popup"
    :id="'address-pop-up'+addresslist.customer_address_id"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    ref="vuemodal"
    data-backdrop="static" data-keyboard="false"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <button type="button" class="close" @click="closeEditAddress()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <h2 class="page-heading text-center">Edit Address</h2>
          <div class="bottom-section1">
            <div
              class="alert alert-success"
              role="alert"
              v-show="enablesavesucadrsmesg"
            >{{ getsaveaddressmsg}}</div>

            <div
              class="alert alert-danger"
              role="alert"
              v-show="enableerradrsmessage"
            >{{ geterraddrssmsg}}</div>
            <form
              class="address-form form-horizontal animated-form"
              @submit.prevent="saveAddress(addresslist,addresslist.customer_address_id)"
              method="post"
            >
              <div class="form-row">
                <div class="form-group checkbox col-md-12">
                  <input
                    type="checkbox"
                    :id="addresslist.customer_address_id+'edit_default_address'"
                    v-model="edit_default_address"
                    :disabled="edit_default_address"
                    :name="addresslist.customer_address_id+'edit_default_address'"
                  >
                  <label :for="addresslist.customer_address_id+'edit_default_address'">Default Address</label>
                </div>
              </div>
              <div class="form-row-gmap" v-if="use_google_map == 1">
                    <GmapMap
                    :center="{lat:this.latitude, lng:this.longitude}"
                    @click="center=m.position"
                    :zoom="zoomProperty"
                    map-type-id="roadmap"
                    ref="EditAddressGoogleMap"
                    style="width:100%; height: 300px;margin-bottom: 30px;margin-left: auto;margin-right: auto;"
                    >
                    <GmapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="true"
                    @click="center=m.position"
                    @dragend="updateCoordinates"
                    />
                    </GmapMap>

                    </div>    
                    <div class="form-row form-row-autocomplete" v-if="use_google_map == 1">
                    <div class="form-group col-md-12 active">
                    <form method="post" class="autoCompleteAddress">
                    <div>
                    <vue-google-autocomplete
                    :ref="addresslist.customer_address_id+'address'"
                    :id="addresslist.customer_address_id+'edit_address_autocomplete'"
                    classname="form-control"
                    placeholder="Please type your address"
                    v-on:placechanged="getAddressData"
                    @keypress.enter="$event.preventDefault()"
                    v-model="formatted_address"
                    types=""
                    :country="country_name"
                    >
                    </vue-google-autocomplete>
                    <div @click="getLocation()" class="location_icon">
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 183.8 183.8" style="enable-background:new 0 0 183.8 183.8;" xml:space="preserve">
                        <g>
                          <path d="M182.2,96.6c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.3,0.3-0.5c0,0,0-0.1,0.1-0.1
                            c0.1-0.2,0.2-0.4,0.3-0.6c0,0,0-0.1,0.1-0.1c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3
                            c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
                            c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
                            c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0-0.8-0.1-1.5-0.4-2.2c0,0,0-0.1,0-0.1c-0.1-0.2-0.1-0.4-0.2-0.6
                            c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.6-0.7-1.1-1.2-1.5c-1.5-1.5-3.5-2.5-6-2.6c-0.1,0-0.2,0-0.3,0
                            c0,0-0.1,0-0.1,0c-0.2,0-0.3,0-0.5,0c-2.8-0.1-5.7-0.2-8.5-0.1c-0.4,0-0.8,0-1.2,0c-1,0-2.1,0-3.1,0c-1.3-0.2-2.1-0.9-2.6-2.1
                            c0-0.1,0-0.2-0.1-0.2c-1.4-8.3-4-15.9-7.8-22.9c-2-3.6-4.2-7-6.8-10.1c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3-0.3-0.6-0.7-0.8-1
                            c-3.3-3.8-7-7.2-11.2-10.2c-0.3-0.2-0.5-0.4-0.8-0.6c-7.5-5.4-16-9.1-25.2-11.2c-1.6-0.4-3.2-0.8-4.8-1.1c-0.4-0.3-0.7-0.6-1-1
                            c-0.4-0.5-0.6-1.1-0.8-1.7c0-0.9,0-1.8,0-2.6c0.1-3.3,0.1-6.5,0-9.8c0-0.1,0-0.3,0-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3
                            C99.6,5.6,98.6,3.5,97,2C97,2,97,2,97,1.9c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.2
                            c-0.1,0-0.1-0.1-0.2-0.1C96,1.2,95.8,1.1,95.7,1c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c0,0-0.1,0-0.1-0.1
                            c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
                            c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0
                            c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0C92,0,92,0,92,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0
                            c0,0,0,0-0.1,0c-0.8,0-1.5,0.1-2.2,0.4c0,0-0.1,0-0.1,0c-0.2,0.1-0.4,0.1-0.6,0.2c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0-0.2,0.1-0.3,0.1
                            c-0.6,0.3-1.1,0.7-1.5,1.2c-1.5,1.5-2.5,3.5-2.6,6c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,0.2,0,0.3,0,0.5c-0.1,2.8-0.2,5.7-0.1,8.5
                            c0,0.4,0,0.8,0,1.2c0,1,0,2.1,0,3.1c-0.2,1.3-0.9,2.1-2.1,2.6c-0.1,0-0.2,0-0.2,0.1c-8.3,1.4-15.9,4-22.9,7.8
                            c-3.6,2-7,4.2-10.1,6.8c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3,0.3-0.7,0.6-1,0.8c-3.8,3.3-7.2,7-10.2,11.2c-0.2,0.3-0.4,0.5-0.6,0.8
                            c-5.4,7.5-9.1,16-11.2,25.2c-0.4,1.6-0.8,3.2-1.1,4.8c-0.3,0.4-0.6,0.7-1,1c-0.5,0.4-1.1,0.6-1.7,0.8c-0.9,0-1.8,0-2.6,0
                            c-3.3-0.1-6.5-0.1-9.8,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-2.5,0.1-4.6,1.2-6.1,2.7c0,0-0.1,0.1-0.1,0.1
                            C1.9,86.9,1.8,87,1.8,87c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.2C1.2,87.8,1.1,88,1,88.2
                            c0,0,0,0.1-0.1,0.1c-0.1,0.2-0.2,0.4-0.3,0.6c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2
                            c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1
                            c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1
                            c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.8,0.1,1.5,0.4,2.2c0,0,0,0.1,0,0.1c0.1,0.2,0.1,0.4,0.2,0.6
                            c0.1,0.1,0.1,0.3,0.2,0.4c0,0.1,0.1,0.2,0.1,0.3c0.3,0.6,0.7,1.1,1.2,1.5c1.5,1.5,3.5,2.5,6,2.6c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0
                            c0.2,0,0.3,0,0.5,0c2.8,0.1,5.7,0.2,8.5,0.1c0.4,0,0.8,0,1.2,0c1,0,2.1,0,3.1,0c1.3,0.2,2.1,0.9,2.6,2.1c0,0.1,0,0.2,0.1,0.2
                            c1.4,8.3,4,15.9,7.8,22.9c2,3.6,4.2,7,6.8,10.1c0.1,0.1,0.2,0.2,0.2,0.3c0.3,0.3,0.6,0.7,0.8,1c3.3,3.8,7,7.2,11.2,10.2
                            c0.3,0.2,0.5,0.4,0.8,0.6c7.5,5.4,16,9.1,25.2,11.2c1.6,0.4,3.2,0.8,4.8,1.1c0.4,0.3,0.7,0.6,1,1c0.4,0.5,0.6,1.1,0.8,1.7
                            c0,0.9,0,1.8,0,2.6c-0.1,3.3-0.1,6.5,0,9.8c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0.1,2.5,1.2,4.6,2.7,6.1
                            c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0.1,0.2,0.1
                            c0.2,0.1,0.3,0.2,0.5,0.3c0,0,0.1,0,0.1,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0,0,0.1,0,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0
                            c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0.1
                            c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0
                            c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.8,0,1.5-0.1,2.2-0.4
                            c0,0,0.1,0,0.1,0c0.2-0.1,0.4-0.1,0.6-0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0,0.2-0.1,0.3-0.1c0.6-0.3,1.1-0.7,1.5-1.2
                            c1.5-1.5,2.5-3.5,2.6-6c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0-0.5c0.1-2.8,0.2-5.7,0.1-8.5c0-0.4,0-0.8,0-1.2
                            c0-1,0-2.1,0-3.1c0.2-1.3,0.9-2.1,2.1-2.6c0.1,0,0.2,0,0.2-0.1c8.3-1.4,15.9-4,22.9-7.8c3.6-2,7-4.2,10.1-6.8
                            c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.3,0.7-0.6,1-0.8c3.8-3.3,7.2-7,10.2-11.2c0.2-0.3,0.4-0.5,0.6-0.8c5.4-7.5,9.1-16,11.2-25.2
                            c0.4-1.6,0.8-3.2,1.1-4.8c0.3-0.4,0.6-0.7,1-1c0.5-0.4,1.1-0.6,1.7-0.8c0.9,0,1.8,0,2.6,0c3.3,0.1,6.5,0.1,9.8,0c0.1,0,0.3,0,0.4,0
                            c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c2.5-0.1,4.6-1.2,6.1-2.7c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2
                            C182.1,96.7,182.1,96.7,182.2,96.6z M118.1,137.4c-7.6,4.1-16.5,6.4-26.5,6.6c-0.6,0.1-1.3,0.1-2,0.1c-9.3-0.6-17.6-3-24.7-7.1
                            c-7.9-4.7-14.2-11.4-18.5-18.9c-4.1-7.6-6.4-16.5-6.6-26.5c-0.1-0.6-0.1-1.3-0.1-2c0.6-9.3,3-17.6,7.1-24.7
                            c4.7-7.9,11.4-14.2,18.9-18.5c7.6-4.1,16.5-6.4,26.5-6.6c0.6-0.1,1.3-0.1,2-0.1c9.3,0.6,17.6,3,24.7,7.1
                            c7.9,4.7,14.2,11.4,18.5,18.9c4.1,7.6,6.4,16.5,6.6,26.5c0.1,0.6,0.1,1.3,0.1,2c-0.6,9.3-3,17.6-7.1,24.7
                            C132.3,126.7,125.6,133,118.1,137.4z"/>
                          <path d="M117.4,86.9c-1-5.4-3.8-10.2-7.7-13.8c-4.6-4.4-10.8-7.2-17.6-7.2c-1.8,0-3.6,0.2-5.3,0.5c-5.4,1-10.2,3.8-13.8,7.7
                            c-4.4,4.6-7.2,10.8-7.2,17.6c0,1.8,0.2,3.6,0.5,5.3c1,5.4,3.8,10.2,7.7,13.8c4.6,4.4,10.8,7.2,17.6,7.2c1.8,0,3.6-0.2,5.3-0.5
                            c5.4-1,10.2-3.8,13.8-7.7c4.4-4.6,7.2-10.8,7.2-17.6C117.9,90.4,117.7,88.6,117.4,86.9z M99.8,98.4c-0.5,0.6-1.1,1.1-1.7,1.6
                            c-1.7,1.2-3.8,1.9-6.3,1.9c-2.6,0-4.8-0.8-6.5-2.1c-0.6-0.5-1.1-1.1-1.6-1.7c-1.2-1.7-1.9-3.8-1.9-6.3c0-2.5,0.8-4.8,2.1-6.5
                            c0.5-0.6,1.1-1.1,1.7-1.6c1.7-1.2,3.8-1.9,6.3-1.9c2.6,0,4.8,0.8,6.5,2.1c0.6,0.5,1.1,1.1,1.6,1.7c1.2,1.7,1.9,3.8,1.9,6.3
                            C101.9,94.5,101.2,96.7,99.8,98.4z"/>
                        </g>
                      </svg>
                    </div>
                    </div>
                    </form>
                    </div>
                    </div>
              <div class="form-row">
              </div>
              <div class="form-row">
                <div class="form-group col-md-12 active">
                  <label :style="textcolor" class="control-label" for="edit_address">
                    Apt Number
                  </label>
                  <input
                    type="text"
                    name="edit_address"
                    class="form-control"
                    id="edit_address"
                    v-model="edit_address"
                    :class="{error: errors.has('edit_address')}"
                  >
                  <span
                    v-show="errors.has('edit_address')"
                    class="help is-danger"
                  >{{ $entrallfldrqrd}}</span>
                </div>
              </div>
              <div class="form-group">
                <div class="form-group col-md-12 d-flex flex-wrap justify-content-center">
                  <div
                    class="radio-dt"
                    v-for="(location, index) in edit_location_types"
                    v-bind:key="index"
                  >
                    <input
                      type="radio"
                      :id="'edit_'+addresslist.customer_address_id+'_'+location.val"
                      v-model="edit_location_type"
                      :value="index"
                      @click="selectLocationType(location.id)"
                    >
                    <label :for="'edit_'+addresslist.customer_address_id+'_'+location.val">{{location.val}}</label>
                  </div>
                </div>
              </div>
              <div class="form-row" v-if="edit_other_location">
                <div class="form-group col-md-12 active">
                  <label :style="textcolor" class="control-label" for="edit_tag">Tag</label>
                  <input type="text" name="edit_tag" class="form-control" v-model="edit_tag">
                </div>
              </div>

              <div class="form-group text-center">
                <button :style="colorObj" class="btn add-btn hoverEffect" id="updtbtn">Update</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { bus } from '../../main'
import helper from '../../store/helper';
import localconfig from "../../global_settings.json"
import axios from "axios";
export default {
  name: "EditAddressbook",
  components: {
    'vue-google-autocomplete': VueGoogleAutocomplete,
  },
  props: {
    addresslist: {
      type: null
    },
  },
  mixins:[helper],
  data() {
    return {
      userprofile: null,
      enablesavesucadrsmesg: false,
      country_filter_code:'',
      getsaveaddressmsg: "",
      enableerradrsmessage: false,
      geterraddrssmsg: "",
      customer_address: {
        customer_address_id: "",
        user_id: "",
        is_default: "",
        alias: "",
        alias_code: "",
        country_id:"",
        address1: "",
        address2: "",
        city_id: "",
        area_id: "",
        zipcode: "",
        street:"",
        latitude:"",
        longitude:""
      },
      edit_default_address: "",
      edit_address: "",
      edit_address2: "",
      edit_zipcode1:"",
      street:"",
      edit_country:"",
      edit_state:"",
      edit_city:"",
      edit_area_id: "",
      edit_state_id: "",
      edit_country_id:"",
      edit_city_id: "",
      edit_pincode: "",
      edit_location_type: "",
      edit_tag: "",
      edit_other_location: false,
      edit_location_types: [
        { id: "0", val: "Home" },
        { id: "1", val: "Office" },
        { id: "2", val: "Other" }
      ],
      states: null,
      cities: null,
      areas: null,
      statebody: {
        country: ""
      },
      citybody: {
        state: ""
      },
      areabody: {
        city: ""
      },
      markers:[],
      center: { lat:"", lng:"" },
      latitude:"",
      longitude:"",
      formatted_address:"",
      updated_latitude:"",
      updated_longitude:"",
      country_name:[],
      is_business_model:0,
      is_alpha_numeric:"",
      pincode_length:0,
      countries_name:[],
      use_google_map:1,
      zoomProperty : 18,
      is_address_edit:0
    };
  },
  mounted() {    
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
    this.statebody.country = localStorage.getItem("country");
    this.country_filter_code = localStorage.getItem("country");
    this.pincode_length = localStorage.getItem("pincode_length");
    this.edit_address = this.addresslist.address;
    this.edit_address2 = this.addresslist.address2;
    this.edit_area_id = this.addresslist.area_id;
    this.edit_pincode = this.addresslist.zipcode;
    this.edit_country_id = this.addresslist.country_id;
    this.edit_state_id = this.addresslist.state_id;
    this.edit_city_id = this.addresslist.city_id;
    this.edit_area_id = this.addresslist.area_id;
    this.edit_location_type = this.addresslist.alias_code;
    this.latitude = parseFloat(this.addresslist.lat);
    this.longitude= parseFloat(this.addresslist.long);
    this.getAutoComplete(parseFloat(this.addresslist.lat), parseFloat(this.addresslist.long))
    
    if(this.edit_location_type == 2) {
      this.edit_other_location = true;
      this.edit_tag = this.addresslist.alias;
    }
    this.edit_default_address = this.addresslist.is_default == "1" ? true : false;
    window.$(this.$refs.vuemodal).on("show.bs.modal", this.onModalShow);
    this.showGMap();
    this.use_google_map = parseFloat(localStorage.getItem("use_google_map"));

    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0){
        this.country_filter_code = JSON.parse(data).country;
        this.statebody.country = JSON.parse(data).country;
        this.is_business_model = JSON.parse(data).business_model;
        this.is_alpha_numeric = JSON.parse(data).allow_alphanumeric_pincode;
        this.pincode_length = JSON.parse(data).pincode_length;
      } else {
        this.country_filter_code = JSON.parse(data).country;
        this.statebody.country = JSON.parse(data).country;
        this.is_business_model = localconfig.business_model;
      }
    })
    this.originalColor();
    this.country_name = localStorage.getItem("country_codes");
    this.country_name = this.country_name.split(",");
  },
  updated(){
		window.$(document).ready(function(){
			window.$('.location_icon svg path').css({fill: localStorage.getItem("button_default_color")})
		})
	},
  methods: {
    containsNumber(str) {
      return /[0-9]/.test(str);
    },
    async getLocation() {
			try {
				await this.$getLocation({
					enableHighAccuracy: true
				}).then(coordinates => {
          this.latitude = parseFloat(coordinates.lat);
          this.longitude = parseFloat(coordinates.lng);

          this.updated_latitude = parseFloat(coordinates.lat);
          this.updated_longitude = parseFloat(coordinates.lng);

          this.showGMap()
          this.getStreetAddressFrom(coordinates.lat,coordinates.lng,true)
				});

			} catch (error) {
         this.latitude = parseFloat(localStorage.getItem("default_location_lat"));
         this.longitude = parseFloat(localStorage.getItem("default_location_long"));

         this.updated_latitude = parseFloat(localStorage.getItem("default_location_lat"));
         this.updated_longitude = parseFloat(localStorage.getItem("default_location_long"));

         this.showGMap()
         this.getStreetAddressFrom(this.latitude,this.longitude,true);
			}
		},
    closeEditAddress(){
      window.location.href = "/profile#address-book";
      window.location.reload();
    },
    getAddressData(addressData) {
      this.$refs.EditAddressGoogleMap.$mapPromise.then((map) => {
        map.setZoom(18);
      })
			this.latitude = addressData.latitude;
      this.longitude = addressData.longitude;
      this.updated_latitude = addressData.latitude;
      this.updated_longitude = addressData.longitude;
      window.$('[id*=edit_address_autocomplete]').val(addressData.street_number+" "+addressData.route+", "+addressData.locality+", "+addressData.administrative_area_level_1+" "+addressData.postal_code+", "+addressData.country)
      this.edit_address2 = window.$('[id*=edit_address_autocomplete]').val();
      this.edit_zipcode1 = addressData.postal_code;
      this.street = addressData.street_number+" "+addressData.route;
      this.edit_country = addressData.country;
      this.edit_state = addressData.administrative_area_level_1;
      this.edit_city = addressData.locality;
      this.is_address_edit = 1;
      this.showGMap();
		},
    updateCoordinates(location) {
      this.updated_latitude = location.latLng.lat();
      this.updated_longitude = location.latLng.lng();
      this.getStreetAddressFrom(parseFloat(location.latLng.lat()),parseFloat(location.latLng.lng()),true)
    },
    async getAutoComplete(lat, long){
      var _this=this;
			try {
				var {
					data
				} = await axios.get(
				"https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
				lat +
				"%2C" +
				long +
				"&key="+((localStorage.getItem("google_map_key")!=null && localStorage.getItem("google_map_key")!="" && localStorage.getItem("google_map_key")!=undefined) ? localStorage.getItem("google_map_key") : 'AIzaSyDU1yuRqsrT2TFdn5srqr5F9iDzdDqzVL4')
				);
				if (data.error_message) {
					console.log(data.error_message)
				} else {
          _this.formatted_address = this.addresslist.address2;

          }}
              catch(error){console.log(error)}
    },
    async getStreetAddressFrom(lat, long,formatedAddress) {
      var _this=this;
      this.edit_country_id = 0;
      this.edit_state_id = '';
      this.edit_city_id = '';
      this.edit_area_id = '';
      this.edit_address2 = '';
      this.edit_pincode = ''; 

			try {
				var {
					data
				} = await axios.get(
				"https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
				lat +
				"%2C" +
				long +
				"&key="+((localStorage.getItem("google_map_key")!=null && localStorage.getItem("google_map_key")!="" && localStorage.getItem("google_map_key")!=undefined) ? localStorage.getItem("google_map_key") : 'AIzaSyDU1yuRqsrT2TFdn5srqr5F9iDzdDqzVL4')
				);
				if (data.error_message) {
					console.log(data.error_message)
				} else {
              if (formatedAddress)
                 _this.formatted_address = data.results[0].formatted_address;

              let returnData = {};
              const ADDRESS_COMPONENTS = {
                  subpremise : 'short_name',
                  street_number: 'short_name',
                  route: 'long_name',
                  locality: 'long_name',
                  administrative_area_level_1: 'long_name',
                  administrative_area_level_2: 'long_name',
                  country: 'long_name',
                  postal_code: 'short_name'
              };

              for (let i = 0; i < data.results[0].address_components.length; i++) {
                  let addressType = data.results[0].address_components[i].types[0];

                  if (ADDRESS_COMPONENTS[addressType]) {
                      let val = data.results[0].address_components[i][ADDRESS_COMPONENTS[addressType]];
                      returnData[addressType] = val;
                  }
              }

            
              this.edit_address2 = data.results[0].formatted_address;
              window.$('[id*=edit_address_autocomplete]').val(this.edit_address2)
              this.edit_zipcode1 = returnData.postal_code;
              this.street = returnData.street_number+" "+returnData.route;
              this.edit_country = returnData.country;
              this.edit_state = returnData.administrative_area_level_1;
              this.edit_city = returnData.locality;
              this.is_address_edit = 1;
				}
			} catch (error) {
				console.log(error);
			}
		},
    async showGMap() {
      const marker = { lat: parseFloat(this.latitude), lng: parseFloat(this.longitude) };
      this.markers=[];
      this.markers.push({ position: marker });
      this.center = marker;    
    },
    selectLocationType(location_id) {
      if (location_id == 2) {
        this.edit_other_location = true;
      } else {
        this.edit_other_location = false;
      }
    },
    async getCountries(){
      this.countries = this.country_filter_code;
      this.countries_name = null;
      this.$store.dispatch("getcountries", this.countries).then(response => {
        this.countries_name = JSON.parse(JSON.stringify(response.data));
        this.edit_country_id = this.addresslist.country_id;
        this.getStates();
      });
    },
    getStates() {
      this.statebody.country = this.edit_country_id;
      this.$store.dispatch("getstates", this.statebody).then(response => {
        this.states = JSON.parse(JSON.stringify(response.data));
        this.edit_state_id = this.addresslist.state_id;
        this.getCities();
      });
    },
    getCities() {
      this.citybody.state = this.edit_state_id;
      this.$store.dispatch("getcities", this.citybody).then(response => {
        this.cities = JSON.parse(JSON.stringify(response.data));
        this.edit_city_id = this.addresslist.city_id;
        this.getAreas();
      });
    },
    getAreas() {
      this.areabody.city = this.edit_city_id;
      this.areas = null;
      this.$store.dispatch("getarealist", this.areabody).then(response => {
        this.areas = JSON.parse(JSON.stringify(response.data));
        this.edit_area_id = this.addresslist.area_id;
      });
    },
    getAreaName(areaid) {
      this.areabody.city = areaid;
      this.areas = null;
      this.$store.dispatch("getarealist", this.areabody).then(response => {
        this.areas = JSON.parse(JSON.stringify(response.data));
        this.edit_area_id = this.addresslist.area_id;
      });
    },    
    onModalShow() {
      this.getCountries();
    },
    saveAddress(address,address_id) {
      if (!((this.edit_zipcode1 != null && this.edit_zipcode1.indexOf('undefined') <= -1) && (this.edit_city != null && this.edit_city.indexOf('undefined') <= -1) && (this.edit_state != null && this.edit_state.indexOf('undefined') <= -1) && (this.edit_country != null && this.edit_country.indexOf('undefined') <= -1) && (this.street != null && this.street.indexOf('undefined') <= -1))){
        this.$toast.error("Select valid address with Street number Name, City, State and Country.")
        return false;
      }
      this.latitude = this.updated_latitude;
      this.longitude = this.updated_longitude;
      this.$validator.validate();
      this.$validator.validateAll().then(result => {
        if (result) {
          this.customer_address.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
          this.customer_address.is_default = this.edit_default_address == true ? "1" : "0";

          if (this.edit_location_type == "2" && this.edit_tag != "") {
            this.customer_address.alias = this.edit_tag;
          } else {
            this.customer_address.alias = this.edit_location_types[this.edit_location_type].val;
          }

          this.customer_address.alias_code = this.edit_location_type;
          this.customer_address.address1 = this.edit_address;
          this.customer_address.address2 = this.edit_address2;
          this.customer_address.country =  this.is_address_edit == 0 ? address.country_name1 : this.edit_country;
          this.customer_address.state = this.is_address_edit == 0 ? address.state_name1 : this.edit_state;
          this.customer_address.city = this.is_address_edit == 0 ? address.city_name1 : this.edit_city;
          this.customer_address.zipcode1 = this.is_address_edit == 0 ? address.zipcode1 : this.edit_zipcode1;
          this.customer_address.street =  this.is_address_edit == 0 ? address.street : this.street;
          this.customer_address.customer_address_id = address_id;
          this.customer_address.latitude = this.is_address_edit == 0 ? address.lat : this.latitude;
          this.customer_address.longitude = this.is_address_edit == 0 ? address.long : this.longitude;
          this.$store
            .dispatch("managecustomeraddrs", this.customer_address)
            .then(response => {
              if (JSON.parse(JSON.stringify(response)).status == "1") {
                this.enablesavesucadrsmesg = false;
                this.getsaveaddressmsg = JSON.parse(JSON.stringify(response)).message;
                this.enablesavesucadrsmesg = false;
                if (this.enablesavesucadrsmesg == false) {
                  this.$validator.reset();
                  this.$emit("update", "update_address");
                  window.$("#address-pop-up" + address_id).modal("hide");
                  this.$toast.success("Address updated successfully");
                  if (this.customer_address.is_default == 1) {
                    var default_address_set = JSON.parse(localStorage.getItem("location_details"));
                    default_address_set.customer_address_id = this.customer_address.customer_address_id;
                    localStorage.setItem("location_details",JSON.stringify(this.customer_address));
                    window.location.reload();                    
                  }

                }
              } else {
                this.enableerradrsmessage = true;
                this.geterraddrssmsg = JSON.parse(JSON.stringify(response)).message;
                setTimeout(() => (this.enableerradrsmessage = false), 3000);
              }
            });
        }
      });
    }
  }
};
</script>