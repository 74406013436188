<template>
  <div>
    <div class="row order-box-row" v-if="khatalist.status==0">
      <div class="basket-order col-lg-12 mt-4 mt-lg-0" v-if="khatalist.status==0">
        <div class="table-responsive basket-list">
          <div class="empty-box text-center">
            <div class="contents">
              <div class="empty-image">
                <img src="../../assets/images/profile-icons/my-khata.svg" alt="empty-box">
              </div>
            <h3>{{khatalist.message}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row order-box-row" v-if="khatalist.status!=0 && khatalist.data!=''">
      <div class="col-lg-6" v-for="(khata,index) in khatalist.data" v-bind:key="index">
        <div class="order-box">
          <div class="profile-id">
            <div class="media">
              <div class="ordered-profile mr-3">
                <router-link :to="'/shopdetail?storeid='+khata.store_id">
                  <img
                    :src="khata.store_logo"
                    alt="..."
                    v-if="khata.store_logo!=''"
                  >
                  <avatar
                    :fullname="khata.store_name.split(' ')[0]"
                    :size="80"
                    v-if="khata.store_logo==''"
                  ></avatar>
                </router-link>
              </div>

              <div class="media-body">
                <div class="row form-row">
                  <div class="col-sm-7 order-description">
                    <router-link :to="'/shopdetail?storeid='+khata.store_id" style="color:#000;">
                      <h5>{{khata.store_name}}</h5>
                    </router-link>
                    <!-- <a href="javascript:void(0)" class="d-block" @click="getStoreByCategory(khata)" :title="khata.category_name">
                      <h6>{{khata.category_name}}</h6>
                    </a> -->
                    <a class="location" href="#">
                      <img src="../../assets/images/location-icon-gray.png" alt="location-icon.png">
                      <!-- <span v-if="khata.area_name"> {{khata.area_name}}</span> -->
                      <span v-if="khata.city_name"> {{khata.city_name}}</span>
                      <span v-if="khata.city_name">, {{khata.state_name}}</span>
                    </a>
                  </div>
                  <div class="col-sm-5 delivery-description text-sm-right mt-3 mt-sm-0">
                    <div class="icon">
                      <img :src="khata.category_black_icon" alt="vegitables-icon">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="order-details">
            <div class="price row mx-0 mt-3 align-items-center justify-content-center">
              <h5>Total Overdue</h5>
              <h3 class="main-price">
                <span v-html="priceFormatter(khata.amount)"></span>
              </h3>
              <a
                class="view-details"
                href="javascript:void(0)"
                @click="getKhataDetails(khata.store_id)"
              >View</a>
            </div>
          </div>
        </div>
      </div>

      <Khatadetail :khatastoreid="test" :khatadetails="getkhatadetail" :khatalabel="khatalabel"></Khatadetail>
    </div>
  </div>
</template>
<script>
import { bus } from "../../main";
import Avatar from "vue-avatar-component";
import Khatadetail from "../khata/Khatadetail";
import helper from "../../store/helper";
export default {
  name: "Khata",
  components: {
    Avatar,
    Khatadetail
  },
  mixins:[helper],
  data() {
    return {
      currency_symbol: "₹",
      profile: {
        user_id: ""
      },
      userid: "",
      active: 0,
      khatadetail: {
        user_id: "",
        store_id: ""
      },
      showModal: false,
      test: 0,
      khatalist: [],
      getkhatadetail: [],
      category: {
        shop_category_id: "",
        shop_category_name: "",
        type: "",
      },
      khatalabel: '',
      iskhataallow:0,
      show_price_same_font:1
    };
  },
  mounted() {
    this.getKhatalist();
    this.userid =
      localStorage.getItem("user") != null
        ? JSON.parse(localStorage.getItem("user")).user_id
        : "0";
    this.currency_symbol = localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹";
    
    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0){
        this.show_price_same_font = JSON.parse(data).show_price_same_font;
        this.khatalabel = JSON.parse(data).khata_label;
        this.iskhataallow = JSON.parse(data).allow_khata;
      }
    });
  },
  updated(){
    window.$('.order-box .price .view-details').css('color',localStorage.getItem("button_default_color"))
  },
  methods: {
    newabc() {
      this.test = "sdfs";
    },
    getKhataDetails(khataid) {
      this.test = khataid;
      this.getKhatDetls(khataid);
      window.$("#khata-details-popup").modal("show");
    },
    getKhatDetls(khataid) {
      this.khatadetail.user_id =
        localStorage.getItem("user") != null
          ? JSON.parse(localStorage.getItem("user")).user_id
          : "0";
      this.khatadetail.store_id = khataid;
      this.$store
        .dispatch("getcustomerkhatadetail", this.khatadetail)
        .then(response => {
          response.data.store.amount = 0;
          response.data.khata.forEach(function(item){
           if(item.is_credit == 0)
            response.data.store.amount = parseFloat(response.data.store.amount) + parseFloat(item.debit);
           else
            response.data.store.amount = parseFloat(response.data.store.amount) - parseFloat(item.credit);
          })
          bus.$emit("changeItsss", JSON.parse(JSON.stringify(response)));
        });
    },
    getKhatalist() {
      this.profile.user_id =
        localStorage.getItem("user") != null
          ? JSON.parse(localStorage.getItem("user")).user_id
          : "0";

      this.$store.dispatch("getcustomerkhata", this.profile).then(response => {
        if (JSON.parse(JSON.stringify(response)).status == "1") {
          this.khatalist = JSON.parse(JSON.stringify(response));
        } else {
          this.khatalist = JSON.parse(JSON.stringify(response));
        }
      });
    },
    getStoreByCategory(category){
      if(localStorage.getItem("is_business_model")== 3){
        return true;
      } else {
        this.category.shop_category_id = category.category_id;
        this.category.shop_category_name = category.category_name;
        this.category.type = "category";
        localStorage.setItem("selected-category", JSON.stringify(this.category));
        this.$router.push({ path: `/category?categoryid=${this.category.shop_category_id.toString()}` });
      }
    },
    priceFormatter(value) {
      if(value){
        var price = value.toString().split(".")[0];
        var decimal = value.toString().split(".")[1];
        var decimal_font_size = this.show_price_same_font;
        decimal = decimal.length > 2 ? decimal.toFixed(2): decimal;
        var resp = this.currency_symbol + price 
        if(decimal != undefined){
          if(decimal_font_size == 1){
            resp += "<span style='margin:0;'>." + decimal + "</span>";
          } else {
            resp += "<span class='price-decimal' style='margin:0;'><small>." + decimal + "</small></span>";  
          }
        }
        return resp;
      } else {
        return '0';
      }
    },
  }
};
</script>
<style scoped>
@media (max-width: 767px) {
  .order-box .delivery-description {position: initial;}
  .order-box .delivery-description .icon {position: absolute; right: 25px; top: 0;}
}
@media (max-width: 575px) {
  .order-box .price .view-details {width: auto; margin-right: 0; margin-top: 0;}
}
</style>