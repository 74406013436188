<template>
    <div>
        <div class="row order-box-row" >
            <!-- add wishlist -->
            <div class="col-12 text-right">
                <a href="javascript:void(0);" :style="textcolor" @click="addWishlist()">+ ADD WISHLIST </a>
            </div>
            <!-- no wishlist msg-->
            <div v-if="userwishlistdetails.status == 0" class="col-lg-12 mt-4 mt-lg-0 text-center">
                <div class="basket-order wishlist-tab" v-if="userwishlistdetails.status == 0">
                    <div class="table-responsive basket-list">
                        <div class="empty-box text-center">
                            <div class="contents">
                                <div class="empty-image">
                                    <img src="../../assets/images/profile-icons/wishlist.svg" alt="empty-box">
                                </div>
                                <h3>No Records Found</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="wishlist-user row order-box-row" v-if="userwishlistdetails.status!=0 && userwishlistdetails.data!='' && loading == false">
            <div class="col-lg-6" v-for="(wishlist,index) in userwishlistdetails.data" :key="index">
                <div class="order-box">
                    <div class="profile-id">
                        <div class="media">
                            <div class="ordered-profile mr-3">
                                <router-link :to="'/shopdetail?storeid='+wishlist.store_id">
                                    <img 
                                        :src="wishlist.store_logo" 
                                        alt="..." 
                                        v-if="wishlist.store_logo!=''" 
                                        class="rounded-circle" 
                                        style="border-radius:10px !important"
                                    >
                                    <avatar
                                        :fullname="wishlist.store_name.split(' ')[0]"
                                        :size="80"
                                        :color="avatarBackgroundColor"
                                        v-if="wishlist.store_logo == ''"
                                        class="rounded-circle"
                                    ></avatar>
                                </router-link>
                            </div>

                            <div class="media-body">
                                <div class="row form-row">
                                    <div class="col-sm-7 order-description">
                                        <a href="javascript:void(0);" style="color:#000;">
                                            <h5>{{ wishlist.wishlist_name }}</h5>
                                        </a>
                                        <h6>{{ wishlist.item_count}} items</h6>
                                        <router-link :to="'/shopdetail?storeid='+wishlist.store_id"><h6>{{ wishlist.store_name }}</h6></router-link>
                                    </div>
                                </div>
                            </div>
                            <span class="flex"><img :src="wishlist.category_black_icon"></span>
                        </div>
                    </div>
                    <div class="order-details">
                        <div class="wishlist-card price row mx-0 mt-3 align-items-center justify-content-center justify-content-md-end">
                            <a class="view-details" href="javascript:void(0)" title="Add to cart"
                                @click="getWishListDetails(wishlist.wishlist_id)">
                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs></defs><path class="cls-1" d="M40.61,97A9.37,9.37,0,1,1,50,87.65,9.45,9.45,0,0,1,40.61,97Z"/><path class="cls-1" d="M87.49,87.62a9.37,9.37,0,1,1-9.43-9.33A9.46,9.46,0,0,1,87.49,87.62Z"/><path class="cls-1" d="M96.66,15.49c-.46,0-.91,0-1.37,0H26a9.53,9.53,0,0,0-1.07,0,1.32,1.32,0,0,1-1.58-1.2c-.29-1.33-.69-2.64-1-4a49.25,49.25,0,0,0-1.34-4.79A3.6,3.6,0,0,0,17.38,3C12.7,3,8,3,3.33,3A3.07,3.07,0,0,0,0,5.63V7.19C.78,9,2.17,9.62,4.11,9.56c3.28-.09,6.57,0,9.86-.05,1,0,1.41.22,1.68,1.26q7.7,29.09,15.49,58.15C31.79,71.35,32.7,72,35.23,72H83.57a9.73,9.73,0,0,0,1.08,0,3.1,3.1,0,0,0,2.51-4.47,3.31,3.31,0,0,0-3.31-1.77c-15.36,0-30.73,0-46.09,0-.84,0-1.09-.28-1.26-1-.38-1.61-.84-3.21-1.29-4.8-.17-.6-.1-.83.62-.86,6.31-.29,12.61-.62,18.92-.94l35.69-1.79a3.22,3.22,0,0,0,3.31-2.84c1.7-9.3,3.37-18.61,5.09-27.9A42.25,42.25,0,0,1,100,19.89V18.13A3.13,3.13,0,0,0,96.66,15.49Zm-25,25.29c-1.79,0-3.58,0-5.37,0-.56,0-.71.16-.69.71,0,.94,0,1.88,0,2.83s0,1.62,0,2.44a3.13,3.13,0,1,1-6.24,0c0-1.79,0-3.58,0-5.36,0-.53-.16-.65-.66-.65-1.79,0-3.58,0-5.37,0a3.13,3.13,0,1,1,0-6.24c1.78,0,3.57,0,5.36,0,.56,0,.71-.16.7-.7,0-1.76,0-3.52,0-5.27a3.13,3.13,0,1,1,6.24,0c0,1.79,0,3.58,0,5.36,0,.52.15.66.66.65,1.78,0,3.57,0,5.36,0a3.13,3.13,0,1,1,0,6.24Z"/></svg>
                            </a>
                            <a class="view-details" href="javascript:void(0)" @click="editWishlist(wishlist)" title="Edit Wishlist">
                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs></defs><path class="cls-1" d="M100,11.33a8.08,8.08,0,0,1-2.44,3.55c-1.27,1.21-2.47,2.48-3.63,3.64L81.79,6.36c1.62-1.75,3.34-3.68,5.15-5.53A5.78,5.78,0,0,1,88.67,0h2a9.54,9.54,0,0,1,3.71,2.7c1,1,2,2,3,3A9.54,9.54,0,0,1,100,9.37Z"/><path class="cls-1" d="M63.25,16.38a6,6,0,0,0-1,.63Q49.61,29.67,36.93,42.34a11.12,11.12,0,0,0-2.85,4.58c-1.27,3.93-2.63,7.82-3.89,11.75a8.76,8.76,0,0,0,8.58,11.61,10.25,10.25,0,0,0,2.67-.51c4-1.3,8-2.65,12-4a10.22,10.22,0,0,0,4-2.53Q70.22,50.48,83,37.71c.26-.26.54-.51,1-.92v1.15q0,25.38,0,50.77C84,95.4,79.38,100,72.7,100H11.28C4.6,100,0,95.39,0,88.7V27.28C0,20.65,4.6,16,11.21,16H63Z"/><path class="cls-1" d="M77.08,10.53l12.39,12.4c-.17.18-.41.44-.65.68Q71.15,41.28,53.46,58.93a6.21,6.21,0,0,1-2.27,1.41c-3.77,1.32-7.58,2.53-11.36,3.82a3,3,0,0,1-4-4c1.32-3.87,2.58-7.77,3.91-11.64a4.47,4.47,0,0,1,1-1.72q17.94-18,35.94-36Z"/></svg>
                            </a>
                            <a class="view-details" href="javascript:void(0)" @click="duplicateWishlist(wishlist)" title="Duplicate Wishlist">
                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs></defs><path class="cls-1" d="M14.65,100c-1.79-.7-2.37-2-2.37-3.91q.06-34.61,0-69.22A3.22,3.22,0,0,1,13.36,24a3.61,3.61,0,0,1,1.94-.74c8.33-.05,16.66,0,25,0a2.68,2.68,0,0,1,.42.09V24.4q0,9.44,0,18.85A3.79,3.79,0,0,0,41.51,46a3.48,3.48,0,0,0,2.83,1H64.55v1.35c0,15.89,0,31.77,0,47.65,0,1.85-.52,3.21-2.26,4Z"/><path class="cls-1" d="M35.49,17.09V3.6c0-2.46,1.14-3.6,3.57-3.6H63.89V1.1q0,9.52,0,19c0,2.56,1.12,3.69,3.69,3.69H87.72V78c0,2.56-1.12,3.68-3.69,3.68H70.65V80.3q0-17.68,0-35.35a5,5,0,0,0-1.56-3.82Q57.77,29.9,46.52,18.56a4.65,4.65,0,0,0-3.64-1.49C40.47,17.14,38.07,17.09,35.49,17.09Z"/><path class="cls-1" d="M70,4.73c4.32,4.31,8.68,8.68,13,13H70Z"/><path class="cls-1" d="M59.68,40.85H46.89V28.06Z"/></svg>
                            </a>
                            <a class="view-details" href="javascript:void(0)" @click="deleteWishListModal(index)" title="Delete Wishlist">
                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs></defs><path class="cls-1" d="M87.78,29.35H12.16c.2,2.55.4,5,.6,7.52l1.5,18L15.94,75c.47,5.58.89,11.16,1.41,16.73.4,4.38,3.07,7.24,7.39,8.15A.67.67,0,0,1,25,100H75a10.48,10.48,0,0,0,5-2.55,10.15,10.15,0,0,0,2.74-6.79q1.42-17.61,2.9-35.21,1.08-12.89,2.13-25.78A2.51,2.51,0,0,0,87.78,29.35ZM34.38,88.09a2.82,2.82,0,0,1-2-2.65c-.34-5.29-.65-10.58-1-15.87Q30.69,57.69,30,45.81c-.16-2.53-.37-5.06-.44-7.6a2.91,2.91,0,0,1,4.29-2.7A2.83,2.83,0,0,1,35.34,38q.49,7.78,1,15.58.77,12.07,1.51,24.15c.14,2.37.28,4.74.47,7.83A2.83,2.83,0,0,1,34.38,88.09Zm18.54-3c0,2.29-1.88,3.75-3.88,3a2.85,2.85,0,0,1-2-2.55c0-.33,0-.65,0-1V38.88c0-2.35,1.07-3.71,2.93-3.71s2.93,1.36,2.93,3.71V61.72Q52.93,73.39,52.92,85.06ZM69,62q-.63,10.09-1.25,20.17c-.06,1.07-.12,2.14-.2,3.22a2.92,2.92,0,0,1-3,2.84,2.86,2.86,0,0,1-2.83-3.09c.16-3.54.41-7.08.63-10.62Q63,64.33,63.66,54.11c.33-5.36.65-10.72,1-16.08a3,3,0,0,1,3.1-2.85,2.91,2.91,0,0,1,2.75,3.2C70,46.46,69.5,54.25,69,62Z"/><path class="cls-1" d="M93.71,16a5.88,5.88,0,0,0-5.95-4.28H70.5c0-.93,0-1.77,0-2.61A8.82,8.82,0,0,0,61.37,0Q50,0,38.62,0a8.81,8.81,0,0,0-9.13,9.11c0,.83,0,1.67,0,2.6H28.34c-5.4,0-10.8,0-16.21,0a5.82,5.82,0,0,0-5.71,7.82c.82,2.44,3.05,3.89,6,3.89H87.54a8.51,8.51,0,0,0,1.75-.16A5.81,5.81,0,0,0,93.71,16ZM64.64,11.68H35.35c0-.92,0-1.73,0-2.55a3,3,0,0,1,3.27-3.26H61.55a3,3,0,0,1,3.09,3.05C64.66,9.82,64.64,10.73,64.64,11.68Z"/></svg>
                            </a>
                        </div>
                    </div>
                    <div
                        class="modal fade wishlist-alert-modal"
                        id="WishlistDeleteConfirmationModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="WishlistDeleteConfirmationModal"
                        aria-hidden="true"
                        style="z-index:99999;margin-top:85px"
                        :class="isModalVisible == true ? 'show':'hide'"
                    >
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                <h5 class="modal-title" id="RepeatAlertModalLabel" >
                                    <p>Wishlist</p>
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div>
                                <div class="modal-body">
                                    Are you sure you want to delete the wishlist: {{ userwishlistdetails.data[wishlist_index].wishlist_name}}?
                                </div>
                                <div class="modal-footer">
                                <button type="button" data-dismiss="modal" class="btn btn-secondary" >No</button>
                                <button type="button" :style="colorObj" class="btn btn-primary hoverEffect" @click="deleteWishList(wishlist.wishlist_id)">Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="modal fade"
                        id="ReplaceWishlistCartModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="ReplaceWishlistCartModalLabel"
                        aria-hidden="true"
                        >
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="ReplaceWishlistCartModalLabel">Replace cart item(s)?</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">{{ cart_message }}</div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button" :style="colorObj" class="btn repeat-btn hoverEffect" @click="addWishListInCart(wishlist.wishlist_id)">Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="container" v-if="loading==true">
            <p class="text-center">
                <img src="../../assets/images/loading.gif" />
            </p>
        </div>
        
        <p class="text-center" v-if="loading == false && api_response_status == 1">
            <button :disabled="pageNumber === 1" @click="prevPage" :style="colorObj" class="btn button-style hoverEffect">Previous</button>
            &nbsp;
            <button
                :disabled="pageNumber == pageCount"
                :style="colorObj"
                @click="nextPage"
                class="btn button-style hoverEffect"
            >Next</button>
        </p>
    </div>
</template>

<script>
import Avatar from "vue-avatar-component";
import helper from "../../store/helper";
export default {
    name:"Wishlist",
    components: {
        Avatar
    },
    mixins:[helper],
    data(){
        return {
            loading:true,
            userwishlist:{
                user_id:"",
                page:1
            },
            user_id:"",
            api_response_status:"",
            pageNumber: 1,
            size: 20,
            userwishlistdetails:[],
            deletewishlist:{
                wishlist_id:"",
                user_id:""
            },
            deletewishlistmsg:"",
            addtocartwishlist:{
                wishlist_id:"",
                user_id:""
            },
            get_wishlistdetails:{
                wishlist_id:""
            },
            add_to_cart: {
                user_id: "",
                store_id: "",
                item_id: "",
                item_variants_id: "",
                quantity: "",
                cart_type: "",
                choices: "",
                addons: "",
                combo_items: "",
                add_new: 0,
            },
            wishlist_items:null,
            cart_response:"",
            cart_message:"",
            avatarBackgroundColor:"",
            avatarTextColor:"",
            unique_wishlist_name:"",
            wishlist_index:0,
            isModalVisible:false
        }
    },
    computed: {
        pageCount() {
            if (this.api_response_status == "1") {
                let l = this.userwishlistdetails.count,
                    s = this.size;
                return Math.ceil(l / s);
            } else {
                return 0;
            }
        },
    },
    mounted(){
        this.user_id = localStorage.getItem("user")!=null ? JSON.parse(localStorage.getItem("user")).user_id : 0;
        this.originalColor();
        this.getUserWishList();
    },
    updated(){
        window.$('.wishlist-card.price .view-details svg path.cls-1').css({ fill: this.buttonColor.backgroundColor})
        this.avatarBackgroundColor = this.cancelReOrderButton.backgroundColor;
        this.avatarTextColor = localStorage.getItem("css_button_customer_hover_font");
        window.$('.wishlist-user .avatar table tbody tr td').css({ color: this.avatarTextColor});
    },
    methods:{
        nextPage() {
            this.pageNumber++;
            this.getUserWishList();
        },
        prevPage() {
            this.pageNumber--;
            this.getUserWishList();
        },
        getUserWishList(){
            this.loading = true;
            this.userwishlist.user_id = this.user_id;
            this.userwishlist.page = this.pageNumber;
            this.$store.dispatch("customerwishlist", this.userwishlist).then(response => {
                this.api_response_status = response.status;
                this.userwishlistdetails = this.api_response_status == 1 ? response : response;
                setTimeout(() => { this.loading = false}, 5000);
            });
        },
        deleteWishListModal(index){
            this.wishlist_index = index;
            this.isModalVisible = true;
            if(this.isModalVisible){
                window.$("#WishlistDeleteConfirmationModal").modal("show");
            }
        },
        deleteWishList(wishlistid){
            this.deletewishlist.user_id = this.user_id;
            this.deletewishlist.wishlist_id = wishlistid;
            this.$store.dispatch("customerdeletewishlist", this.deletewishlist).then(response => {
                this.getUserWishList();
                this.deletewishlistmsg = response.message;
                window.$("#WishlistDeleteConfirmationModal").modal("hide");
                window.location.reload();
                this.$toast.success("Wishlist details deleted successfully");
            })
        },
        addWishListInCart(wishlistid){
            this.addtocartwishlist.user_id = this.user_id;
            this.addtocartwishlist.wishlist_id = wishlistid;
            this.$store.dispatch("customeraddtocartfromwishlist", this.addtocartwishlist).then(response => {
                if(response.status == 1){
                   this.getUserWishList(); 
                   this.$toast.success("Cart details updated successfully");
                   window.$("#ReplaceWishlistCartModal").modal("hide");
                   return false;
                }
            });
        },
        getWishListDetails(wishlistid){
            this.get_wishlistdetails.wishlist_id = wishlistid;
            this.$store.dispatch("customerwishlistdetails", this.get_wishlistdetails).then(response => {
                if(response.status == 1){
                   this.wishlist_items = response.data;
                   if(this.wishlist_items!=null && this.wishlist_items.items!=null)
                   this.wishlist_items.items.forEach(item => {
                       var obj = {};
                       obj["user_id"] = this.user_id;
                       obj["store_id"] = this.wishlist_items.store_id;
                       obj["item_id"] = item.item_id;
                       obj["item_variants_id"] = item.item_variants_id;
                       obj["quantity"] = item.quantity;
                       obj["combo_items"] = item.combo_variants;
                       item.item_variants.forEach(variant => {
                           obj["choices"] = variant.choices;
                           obj["addons"] = variant.addons;
                       });
                    this.isExistInCart(obj,wishlistid);    
                   });
                }
            })
        },
        isExistInCart(wishlist_item_data,wishlistid){
            this.add_to_cart.user_id = wishlist_item_data.user_id
            this.add_to_cart.store_id = wishlist_item_data.store_id;
            this.add_to_cart.item_id = wishlist_item_data.item_id;
            this.add_to_cart.item_variants_id = wishlist_item_data.item_variants_id;
            this.add_to_cart.quantity = wishlist_item_data.quantity;
            this.add_to_cart.cart_type = 1;
            this.add_to_cart.add_new = 0;
            this.add_to_cart.choices = wishlist_item_data.choices!=null ? wishlist_item_data.choices : "";
            this.add_to_cart.addons = wishlist_item_data.addons!=null ? wishlist_item_data.addons : "";
            this.add_to_cart.combo_items = wishlist_item_data.combo_items!=null ? wishlist_item_data.combo_items : "";
            this.$store.dispatch("addtocartproduct", this.add_to_cart).then(response => {
                this.cart_response = JSON.parse(JSON.stringify(response));
                if (this.cart_response != null) {
                    if (this.cart_response.status == "0") {
                        this.cart_message = this.cart_response.message;
                        window.$("#ReplaceWishlistCartModal").modal("show");
                    } else {
                        this.addWishListInCart(wishlistid);
                    }
                }
            });
        },
        addWishlist(){
            localStorage.removeItem("duplicate_wishlist");
            this.$router.push('/add-wishlist');
        },
        editWishlist(wishlist){
            this.$router.push({ name: 'edit-wishlist', query:{ wishlist_id: wishlist.wishlist_id}});
            localStorage.setItem("edit_wishlist", JSON.stringify(wishlist));
        },
        duplicateWishlist(wishlist){
            this.$router.push({ name: 'add-wishlist', params:{ wishlist_id: wishlist.wishlist_id}});
            localStorage.setItem("duplicate_wishlist", JSON.stringify(wishlist));
        }
    }
}
</script>