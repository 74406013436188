<template>
  <div
    :class="'modal fade newabc'"
    :id="'khata-details-popup'"
    tabindex="-1"
    role="dialog"
    aria-labelledby="khata-popup-label"
    aria-hidden="true"
    ref="vuemodal"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="khata-detail">
          <h2 class="section-heading">{{khata_label}}</h2>
          <span v-if="getkhatadetail!='' && showkhatadetail==false">
            <span v-if="getkhatadetail.data.store.store_id==khatastoreid">
              <div class="order-box">
                <div class="profile-id">
                  <div class="media">
                    <div
                      class="ordered-profile mr-3 d-flex justify-content-center align-items-center"
                    >
                      <span v-if="getkhatadetail.data.store.store_id==khatastoreid">
                        <img
                          :src="getkhatadetail.data.store.store_logo"
                          alt="fruits vegetable"
                          v-if="getkhatadetail.data.store.store_logo!=''"
                          class="rounded-circle"
                        >
                        <avatar
                          :fullname="getkhatadetail.data.store.store_name.split(' ')[0]"
                          :size="80"
                          v-if="getkhatadetail.data.store.store_logo==''"
                          class="rounded-circle"
                        ></avatar>
                      </span>
                    </div>
                    <div class="media-body" v-if="getkhatadetail!=''">
                      <div
                        class="row form-row"
                        v-if="getkhatadetail.data.store.store_id==khatastoreid"
                      >
                        <div class="col-sm-6 order-description">
                          <h5>{{getkhatadetail.data.store.store_name}}</h5>
                          <!-- <h6>{{getkhatadetail.data.store.category_name}}</h6> -->
                          <a class="location" href="#">
                            <img
                              src="../../assets/images/location-icon-gray.png"
                              alt="location-icon.png"
                            >
                            <!-- <span v-if="getkhatadetail.data.store.area_name"> {{getkhatadetail.data.store.area_name}}</span> -->
                            <span v-if="getkhatadetail.data.store.city_name"> {{getkhatadetail.data.store.city_name}}</span>
                            <span v-if="getkhatadetail.data.store.state_name">, {{getkhatadetail.data.store.state_name}}</span>
                          </a>
                        </div>
                        <div class="col-sm-6 delivery-description text-sm-right mt-3 mt-sm-0">
                          <div class="my-auto">
                            <p v-if="getkhatadetail.data.store.number != null" class="mb-2">Credit Time {{getkhatadetail.data.store.number}} {{getkhatadetail.data.store.unit_name}} ({{getkhatadetail.data.store.remaining_days}} remaining)</p>
                            <p v-if="getkhatadetail.data.store.deposit != null" class="mb-2">Deposite Amount: <span v-html="priceFormatter(getkhatadetail.data.store.deposit)"></span></p>
                            <p v-if="getkhatadetail.data.store.remaining_overdue_amount != null" class="mb-2 text-danger">Overdue Limit: <span v-html="priceFormatter(getkhatadetail.data.store.remaining_overdue_amount)"></span> remaining</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-responsive pt-md-4" v-if="getkhatadetail.data!=''">
                <table
                  class="table mb-xl-5 mb-3 product-list"
                  border="0"
                  v-if="getkhatadetail.data.store.store_id==khatastoreid"
                >
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Items</th>
                      <th>Date</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(newkhata,index) in getkhatadetail.data.khata"
                      v-bind:key="index"
                      :class="(newkhata.is_credit==1) ? 'selected':''"
                      id="tablekhata"
                    >
                      <td v-if="newkhata.order_name!='Opening Balance'">
                        <span v-if="newkhata.is_credit!=1">
                          <a
                            href="javascript:void(0)"
                            class="model-btn"
                            @click="getKhataOrderDetail(newkhata.order_id,newkhata.is_credit)"
                          >{{newkhata.order_name}}</a>
                        </span>
                        <span v-else>
                          <a
                            href="javascript:void(0)"
                            class="model-btn"
                            @click="getKhataPaymentDetail(newkhata.order_id,newkhata)"
                          >{{newkhata.order_name}}</a>
                        </span>
                      </td>
                      <td v-else>{{newkhata.order_name}}</td>
                      <td>{{newkhata.item_count}}</td>
                      <td>{{newkhata.date}}</td>
                      <td><span v-html="priceFormatter(newkhata.amount)"></span></td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr class="total">
                      <td colspan="2">Total Overdue</td>
                      <td colspan="2">
                        <span v-html="priceFormatter(getkhatadetail.data.store.amount.toFixed(2))"></span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="button row mx-0 justify-content-center">
                <button :style="colorObj" type="button" @click="redirectToStore()" class="btn button-style order hoverEffect">Send Order to Store</button>
              </div>
            </span>
            <span v-else>
              <span v-if="getkhatadetail!=''">
                <span v-if="getkhatadetail.data.store.store_id!=khatastoreid">
                  <div class="order-box">
                    <div class="profile-id">
                      <p class="text-center">
                        <img src="../../assets/images/loading.gif">
                      </p>
                    </div>
                  </div>
                </span>
              </span>
            </span>
          </span>
          <span v-if="khatadetaildata!='' && showkhatadetail==true">
            <span
              v-if="khatadetaildata.data.store.store_id==khatastoreid && showkhatapayment==false"
            >
              <div class="order-box">
                <div class="profile-id">
                  <div class="media">
                    <div
                      class="ordered-profile mr-3 d-flex justify-content-center align-items-center"
                    >
                      <span v-if="khatadetaildata.data.store.store_id==khatastoreid">
                        <img
                          :src="khatadetaildata.data.store.store_logo"
                          alt="fruits vegetable"
                          v-if="khatadetaildata.data.store.store_logo!=''"
                          class="rounded-circle"
                        >
                        <avatar
                          :fullname="khatadetaildata.data.store.store_name.split(' ')[0]"
                          :size="80"
                          v-if="khatadetaildata.data.store.store_logo==''"
                          class="rounded-circle"
                        ></avatar>
                      </span>
                    </div>
                    <div class="media-body" v-if="khatadetaildata!=''">
                      <div
                        class="row form-row"
                        v-if="khatadetaildata.data.store.store_id==khatastoreid"
                      >
                        <div class="col-sm-6 order-description">
                          <h5>{{khatadetaildata.data.store.store_name}}</h5>
                          <!-- <h6>{{khatadetaildata.data.store.category_name}}</h6> -->
                          <a class="location" href="#">
                            <img
                              src="../../assets/images/location-icon-gray.png"
                              alt="location-icon.png"
                            >
                            <!-- <span v-if="khatadetaildata.data.store.area_name"> {{khatadetaildata.data.store.area_name}}</span> -->
                            <span v-if="khatadetaildata.data.store.city_name"> {{khatadetaildata.data.store.city_name}}</span>
                            <span v-if="khatadetaildata.data.store.state_name">, {{khatadetaildata.data.store.state_name}}</span>
                          </a>
                        </div>
                        <div class="col-sm-6 delivery-description text-sm-right mt-3 mt-sm-0">
                          <div class="my-auto">
                            <p v-if="khatadetaildata.data.store.number != null" class="mb-2">Credit Time {{khatadetaildata.data.store.number}} {{khatadetaildata.data.store.unit_name}} ({{khatadetaildata.data.store.remaining_days}} remaining)</p>
                            <p v-if="khatadetaildata.data.store.deposit != null" class="mb-2">Deposite Amount: <span v-html="priceFormatter(khatadetaildata.data.store.deposit)"></span></p>
                            <p v-if="khatadetaildata.data.store.remaining_overdue_amount != null" class="mb-2 text-danger">Overdue Limit: <span v-html="priceFormatter(khatadetaildata.data.store.remaining_overdue_amount)"></span> remaining</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="khatadetail-inner table-responsive pt-md-4" v-if="khatadetaildata.data!=''">
                <table
                  class="table mb-0 product-list"
                  border="0"
                  v-if="khatadetaildata.data.store.store_id==khatastoreid"
                >
                  <tbody>
                    <tr
                      v-for="(newkhataitms,index) in khatadetaildata.data.items"
                      v-bind:key="index"
                      id="tablekhata"
                    >
                    <td>
                      <table v-for="(variants,index) in newkhataitms.item_variants" :key="index">
                          <tr v-if="variants.is_added == 1">
                            <td>
                                <h6>{{newkhataitms.item_name}}</h6>
                                {{parseFloat(variants.unit_quantity).toFixed(1)}} {{variants.unit_name}}
                            </td>
                            <td>
                              <span v-html="priceFormatter(parseFloat(variants.item_price).toFixed(2))"></span>    
                              <h4>{{Math.round(variants.quantity)}}</h4>
                            </td>
                          </tr>
                      </table>
                    </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  class="table"
                  border="0"
                  v-if="khatadetaildata.data.store.store_id==khatastoreid"
                >
                  <tbody>
                    <tr>
                      <td colspan="2">
                        <h4>Item Total</h4>
                      </td>
                      <td colspan="2">
                        <span v-html="priceFormatter(parseFloat(totalKhataPrice).toFixed(2))"></span>
                      </td>
                    </tr>
                    <tr v-if="khatadetaildata.data.offer_code!=null">
                      <td colspan="2">
                        <h4>Promo - ({{khatadetaildata.data.offer_code}})</h4>
                      </td>
                      <td colspan="2">
                        - <span v-html="priceFormatter(parseFloat(khatadetaildata.data.offer_discount).toFixed(2))"></span>
                      </td>
                    </tr>
                    <tr v-if="khatadetaildata.data.discount!=null">
                      <td colspan="2">
                        <h4>Discount</h4>
                      </td>
                      <td colspan="2">
                        - <span v-html="priceFormatter(parseFloat(khatadetaildata.data.discount).toFixed(2))">
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <h4>Taxes and charges
                          <span class="tax-and-charges cart-section"><b><img src="../../assets/images/svgs/infoicon.svg"/></b>
                            <span class="title-span" v-html="(parseFloat(this.khatadetaildata.data.packaging_cost).toFixed(2) != 0.00 ?'Store Packaging Cost : '+this.currency_symbol+''+parseFloat(this.khatadetaildata.data.packaging_cost).toFixed(2)+ '<br/>':'')+(parseFloat(this.khatadetaildata.data.order_tax).toFixed(2) != 0.00 ?`${store_tax} : `+this.currency_symbol+''+parseFloat(this.khatadetaildata.data.order_tax).toFixed(2):'')">
                            </span>
                          </span>
                        </h4>
                      </td>
                      <td colspan="2">
                        <span v-html="priceFormatter((parseFloat(this.khatadetaildata.data.order_tax)+parseFloat(this.khatadetaildata.data.packaging_cost)).toFixed(2))">  
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="table" border="0">
                  <tbody>
                    <tr>
                      <td colspan="2">
                        <h4>Total</h4>
                      </td>
                      <td colspan="2">
                        <span v-html="priceFormatter(parseFloat(khatadetaildata.data.order_amount).toFixed(2))">  
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="order-details">
                <ul class="details-listing list-unstyled mt-md-1 mb-md-0">
                  <li>
                    <h5>Order Number</h5>
                    <h6 class="mb-0">{{khatadetaildata.data.order_no}}</h6>
                  </li>
                  <li>
                    <h5>Date and Time</h5>
                    <h6 class="mb-0">{{khatadetaildata.data.order_date}}</h6>
                  </li>
                </ul>
              </div>
            </span>
            <span v-else-if="showkhatapayment==true">
              <div class="order-box">
                <div class="profile-id">
                  <div class="media">
                    <div
                      class="ordered-profile mr-3 d-flex justify-content-center align-items-center"
                    >
                      <span v-if="khatadetaildata.data.store.store_id==khatastoreid">
                        <img
                          :src="khatadetaildata.data.store.store_logo"
                          :alt="khatadetaildata.data.store.store_name"
                          v-if="khatadetaildata.data.store.store_logo!=''"
                          class="rounded-circle"
                        >
                        <avatar
                          :fullname="khatadetaildata.data.store.store_name.split(' ')[0]"
                          :size="80"
                          v-if="khatadetaildata.data.store.store_logo==''"
                          class="rounded-circle"
                        ></avatar>
                      </span>
                    </div>
                    <div class="media-body" v-if="khatadetaildata!=''">
                      <div
                        class="row form-row"
                        v-if="khatadetaildata.data.store.store_id==khatastoreid"
                      >
                        <div class="col-sm-6 order-description">
                          <h5>{{khatadetaildata.data.store.store_name}}</h5>
                          <!-- <h6>{{khatadetaildata.data.store.category_name}}</h6> -->
                          <a class="location" href="#">
                            <img
                              src="../../assets/images/location-icon-gray.png"
                              alt="location-icon.png"
                            >
                            <!-- <span v-if="khatadetaildata.data.store.area_name"> {{khatadetaildata.data.store.area_name}}</span> -->
                            <span v-if="khatadetaildata.data.store.city_name"> {{khatadetaildata.data.store.city_name}}</span>
                            <span v-if="khatadetaildata.data.store.city_name">, {{khatadetaildata.data.store.state_name}}</span>
                          </a>
                        </div>
                        <div class="col-sm-6 delivery-description text-sm-right mt-3 mt-sm-0">
                          <div class="my-auto">
                            <p v-if="khatadetaildata.data.store.number != null" class="mb-2">Credit Time {{khatadetaildata.data.store.number}} {{khatadetaildata.data.store.unit_name}} ({{khatadetaildata.data.store.remaining_days}} remaining)</p>
                            <p v-if="khatadetaildata.data.store.deposit != null" class="mb-2">Deposite Amount: <span v-html="priceFormatter(khatadetaildata.data.store.deposit)"></span></p>
                            <p v-if="khatadetaildata.data.store.remaining_overdue_amount != null" class="mb-2 text-danger">Overdue Limit: <span v-html="priceFormatter(khatadetaildata.data.store.remaining_overdue_amount)"></span> remaining</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="order-details">
                <ul class="details-listing list-unstyled mt-md-1 mb-md-0">
                  <li>
                    <input type="text" name="paymentdate" class="form-control mb-2" :value="showkhatapaymentdata.date" disabled>
                  </li>
                  <li>
                    <input type="text" name="paymentamount" class="form-control mb-2" :value="showkhatapaymentdata.amount" disabled>
                  </li>
                  <li>
                    <textarea class="form-control mb-2" disabled :value="showkhatapaymentdata.remarks"></textarea>
                  </li>
                </ul>
              </div>
            </span>
            <span v-else>
              <span v-if="khatadetaildata!=''">
                <span v-if="khatadetaildata.data.store.store_id!=khatastoreid">
                  <div class="order-box">
                    <div class="profile-id">
                      <p class="text-center">
                        <img src="../../assets/images/loading.gif">
                      </p>
                    </div>
                  </div>
                </span>
              </span>
            </span>
            <div class="button row mx-0 justify-content-center">
              <a
                href="javascript:void(0)"
                class="btn order"
                @click="getBackKhataOrderDetail"
                :style="colorObj"
              >Back to {{khata_label}} detail</a>
            </div>
          </span>
          <span v-if="getkhatadetail==''">
            <div class="order-box">
              <div class="profile-id">
                <p class="text-center">
                  <img src="../../assets/images/loading.gif">
                </p>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { bus } from "../../main";
import Avatar from "vue-avatar-component";
import helper from "../../store/helper";
import localconfig from "../../global_settings.json"
export default {
  name: "Khatadetail",
  props: {
    khatastoreid: {
      type: Number
    },
    khatadetails: {
      type: Array
    },
    khatalabel:{
      type: String
    }
  },
  components: {
    Avatar
  },
  mixins:[helper],
  data() {
    return {
      userprofile: null,
      currency_symbol: "₹",
      getkhatadetail: [],
      newkhatadetail: [],
      showkhatadetail: false,
      showkhatapayment: false,
      showkhatapaymentdata: [],
      khatadetaildata: [],
      khatadetail: {
        user_id: "",
        store_id: "",
        loading: false
      },
      TotalOverdue : 0,
      khata_label:'',
      show_price_same_font:1,
      khatadetailvariants:null
    };
  },
  computed: {
    totalKhataPrice() {
      let sum = 0;
      let qty = 0;
      this.khatadetaildata.data.items.forEach(function(item) {
        item.item_variants.forEach(function(data){
          if(data.is_added == 1){
            qty = parseFloat(data.quantity).toFixed(0);
            sum += parseFloat(data.item_price) * qty;
          }
        });
      });
      return parseFloat(sum).toFixed(2);
    },
    taxesAndCharges() {
      let sum = 0;
      sum = parseFloat(this.khatadetaildata.data.packaging_cost).toFixed(2) + parseFloat(this.khatadetaildata.data.order_tax).toFixed(2);
      return parseFloat(sum).toFixed(2);
    }
  },

  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
    this.showkhatadetail = false;
    this.loading = true;
    bus.$on("changeItsss", data => {
      this.loading = false;
      this.getkhatadetail = data;
    });

    this.currency_symbol = localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹";
    this.khata_label = this.khatalabel;
    window.$(this.$refs.vuemodal).on("hidden.bs.modal", this.doSomethingOnHidden);

    window.$(this.$refs.vuemodal).on("show.bs.modal", this.onModalShow);
    this.originalColor();
    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0){
        this.show_price_same_font = JSON.parse(data).show_price_same_font;
      }
    });
    window.$('<style>.khata-detail .table .model-btn:hover { color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
  },
  filters: {
    formatDate: function(date) {
      var dateformat = (localStorage.getItem("date_format")!=null) ? localStorage.getItem("date_format") : localconfig.date_format_web;
      var utc_date = moment.utc(date, 'DD-MM-YYYY HH:mm:ss');
      if(dateformat == 'MM-DD-YYYY HH:mm:ss'){
        return moment(utc_date).format('MM-DD-YYYY HH:mm:ss');
      }
      if(dateformat == 'DD-MM-YYYY HH:mm:ss'){
        return moment(utc_date).format('DD-MM-YYYY HH:mm:ss');
      }  
    },
    formatPaymentDate: function(date) {
      var timezone = (localStorage.getItem("timezone")!=null) ? localStorage.getItem("timezone") : 'Asia/Kolkata';
      var utc_date = moment.utc(date, 'DD-MM-YYYY HH:mm:ss');
      return moment(utc_date).tz(timezone).format('DD MMM YYYY');
    },
  },
  methods: {
    formatConfigDate(date){
      var dateformat = (localStorage.getItem("date_format")!=null) ? localStorage.getItem("date_format") : localconfig.date_format_web;
      var utc_date = moment.utc(date, 'DD-MM-YYYY HH:mm:ss');
      if(dateformat == 'MM-DD-YYYY HH:mm:ss'){
        return moment(utc_date).format('MM-DD-YYYY');
      }
      if(dateformat == 'DD-MM-YYYY HH:mm:ss'){
        return moment(utc_date).format('DD-MM-YYYY');
      }
    },
    onModalShow(){
      var $table = window.$('.khata-detail .table'),
      $bodyCells = $table.find('.khata-detail tbody tr:first').children(),
      colWidth;
      // Adjust the width of thead cells when window resizes
      window.$(window).resize(function() {
          // Get the tbody columns width array
          colWidth = $bodyCells.map(function() {
              return window.$(this).width();
          }).get();
          
          // Set the width of thead columns
          $table.find('.khata-detail thead tr').children().each(function(i, v) {
              window.$(v).width(colWidth[i]);
          });
      }).resize();
    },
    redirectToStore(){
      window.$(this.$refs.vuemodal).modal('hide');
      var storeid=this.getkhatadetail.data.store.store_id;
      this.$router.push({ path: `/shopdetail?storeid=${storeid.toString()}` });
      return false;
    },
    doSomethingOnHidden() {
      this.getkhatadetail = [];
      this.showkhatadetail = false;
    },
    getKhataOrderdata(orderid) {
      this.khatadetail.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.khatadetail.order_id = orderid;
      this.loading = true;
      this.$store
        .dispatch("getcustkhatavieworder", this.khatadetail)
        .then(response => {
          this.khatadetaildata = JSON.parse(JSON.stringify(response));
          this.khatadetailvariants = this.khatadetaildata.data.items;
          this.loading = false;
        });
    },
    getKhataOrderDetail(orderid, iscredit) {
      this.showkhatadetail = this.showkhatadetail == false ? true : false;
      this.showkhatapayment = iscredit == 1 ? true : false;
      this.getKhataOrderdata(orderid);
    },
    getKhataPaymentDetail(orderid, iscreditdata) {
      this.showkhatapaymentdata = [];
      this.showkhatadetail = this.showkhatadetail == false ? true : false;
      this.showkhatapayment = iscreditdata.is_credit == 1 ? true : false;

      this.showkhatapaymentdata = iscreditdata;
      this.getKhataOrderdata(orderid);
    },
    getBackKhataOrderDetail() {
      this.showkhatadetail = this.showkhatadetail == false ? true : false;
    },
    priceFormatter(value) {
      if(value){
        var price = value.toString().split(".")[0];
        var decimal = value.toString().split(".")[1];
        var decimal_font_size = this.show_price_same_font;
        if(decimal != undefined) {
          if(decimal_font_size == 1){
            return this.currency_symbol + price + "<span>." + decimal + "</span>";
          } else {
            return this.currency_symbol + price + "<span class='price-decimal'><small>." + decimal + "</small></span>";
          }
        } else {
          if(decimal_font_size == 1){
            return this.currency_symbol + price + "<span>.00</span>";
          } else {
            return this.currency_symbol + price + "<span class='price-decimal'><small>.00</small></span>";
          }
        }
      } else {
        return '0';
      }
    },
  }
};
</script>